import { appTitle, truncateText } from ".."

// Set distinct and helpful (a.k.a. accessible and bookmarkable) html title

export const setHTMLTitle = (title: string) => {
  if (title.length === 0) {
    // Default html title is set in index.html
    return appTitle
  }

  const truncatedTitle = truncateText({limit: 70, text: title})

  document.title = truncatedTitle
}
