import { css } from "@emotion/react";
import { _minMediaCSS } from "../lib";

const _evaluateGridCSS = css`
  display: grid;
  grid-gap: var(--column-gap);

  ${_minMediaCSS("xs")} {
    grid-template-columns: repeat(2, 1fr); 
  }
  ${_minMediaCSS("s")} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${_minMediaCSS("m")} {
    grid-template-columns: repeat(6, 1fr); 
  }
  ${_minMediaCSS("l")} {
    grid-template-columns: repeat(8, 1fr); 
  }
  ${_minMediaCSS("xl")} {
    grid-template-columns: repeat(10, 1fr); 
  }
  .main-column {
    ${_minMediaCSS("xs")} {
      grid-column: 1 / span 2;
    }
    ${_minMediaCSS("s")} {
      grid-column: 1 / span 4;
    }
    ${_minMediaCSS("m")} {
      grid-column: 1 / span 4;
    }
    ${_minMediaCSS("l")} {
      grid-column: 1 / span 5;
    }
    ${_minMediaCSS("xl")} {
      grid-column: 1 / span 7;
    }
  }
  .side-column {
    max-width: calc(var(--column-width) * 3);
    align-self: flex-start;
    ${_minMediaCSS("xs")} {
      grid-column: span 2;
    }
    ${_minMediaCSS("s")} {
      grid-column: span 3;
    }
    ${_minMediaCSS("m")} {
      grid-column: span 2;
      justify-self: flex-end;
    }
    ${_minMediaCSS("l")} {
      grid-column: span 3;
    }
  }
`

export const _evaluateSummaryCSS = css`
  ${_evaluateGridCSS}
  .evaluate-score-low {
    padding: var(--column-gap);
    background-color: #BC1A23;
    color: #fff;
  }
  .evaluate-score-middle {
    padding: var(--column-gap);
    background-color: #FFD400;
    color: #000;
  }
  .evaluate-score-high {
    padding: var(--column-gap);
    background-color: #0B7A2B;
    color: #fff;
  }
`

export const _evaluateTitle = css`
  font-size: var(--font-size-xxxl);

  /* Keep actual font size the same */
  ${_minMediaCSS("s")} {
    font-size: var(--font-size-xxl);
  }
`

export const _copyContainerCSS = css`
  font-size: var(--font-size-s);
  ${_evaluateGridCSS}
`