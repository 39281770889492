import React from "react";
import { useFocusRef } from "../../lib/useFocusRef";

import {
  loadingIndicatorCSS,
} from "./LoadingIndicator.css";

export const LoadingIndicator = ({message = 'Loading...'}: {message?: string}) => {
  const focusRef = useFocusRef<HTMLHeadingElement>({
    documentCookie: document.cookie,
    dependencies: [], // Run once
    shouldFocus: true
  })

  return (
    <h1 
      css={loadingIndicatorCSS}
      data-testid="loading-indicator"
      ref={focusRef}
      tabIndex={-1}
    >
      {message}
    </h1>
  );
};
