import { css } from "@emotion/react";

export type TBoxColor = "none" | "alert" | "dim" | "info" | "promo" | "success"

export type TBoxVariant = "none" | "background" | "border" | "highlight"

export const _boxCSS = css`
  &.box-with-icon {  
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: var(--column-gap);
  }
  &.box-none {
    margin: calc(var(--column-gap) * 1.5) 0;
    padding: 0 var(--column-gap);
  }
  &.box-background {
    margin: calc(var(--column-gap) * 1.5) 0;
    padding: calc(var(--column-gap) * 1.5) var(--column-gap);
    background: var(--color-surface-dim);
    color: var(--color-on-surface-dim);
    --color-interaction: var(--color-on-surface-dim);
    --color-on-interaction: var(--color-surface-dim);
  }
  &.box-border {
    margin: calc(var(--column-gap) * 1.5) 0;
    padding: calc(var(--column-gap) * 1.5) var(--column-gap);
    box-shadow: inset 0 0 0 4px var(--color-surface-dim);
  }
  &.box-highlight {
    margin: calc(var(--column-gap) * 1.5) 0;
    .box-topper {
      padding: calc(var(--column-gap) * 0.75) var(--column-gap);
      background: var(--color-surface-dim);
      color: var(--color-on-surface-dim-variant);
      border: 1px solid var(--color-on-surface-dim-variant);
      border-right: 0;
      border-left: 0;
    }
    .box-content {
      padding: calc(var(--column-gap) * 1.5) var(--column-gap);
    }
  }
  &.box-alert {
    --color-surface-dim: var(--color-alert-light);
    --color-on-surface-dim: var(--color-on-surface);
    --color-on-surface-dim-variant: var(--color-alert);
  }
  &.box-info {
    --color-surface-dim: var(--color-info-light);
    --color-on-surface-dim: var(--color-on-surface);
    --color-on-surface-dim-variant: var(--color-info-dark);
  }
  &.box-success {
    --color-surface-dim: var(--color-success-light);
    --color-on-surface-dim: var(--color-on-surface);
    --color-on-surface-dim-variant: var(--color-success);
  }
  &.box-promo {
    --color-surface-dim: var(--color-info-dark);
    --color-on-surface-dim: white;
    --color-interaction: white;  
    --color-interaction-dim: white;    
    --color-on-interaction: var(--color-info-dark);
  }
`

export const _boxIconCSS = css`
  width: 2rem;
  height: 2rem;
  color: var(--color-on-surface-dim-variant);
`
