import { TSuggestedJournalData } from "."
import { v5 as uuidv5 } from "uuid"

export const appTitle = "Transfer Your Manuscript"

export const ellipsis = String.fromCharCode(0x2026)
export const emdash = String.fromCharCode(0x2014)

export function formatDate(date: Date | number) {
  let newDate: Date;
  if (typeof date === "number") {
    newDate = new Date(date)
  } else {
    newDate = date
  }

  return newDate.toLocaleDateString("en-NL", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

// There is some kind of a ECMA version conflict with Jenkins so we'll use a regex instead of replaceAll

export function formatJournalTitleAsUrl(journalTitle: string) {
  return journalTitle.toLowerCase().replace(/[" "]/g, "-").replace(/["&"]/g, "and")
}

export function formatStatus(status: string) {
  return status.toLowerCase().replace(/["_"]/g, "-")
}

export const hasSuggestions = (suggestedJournals: TSuggestedJournalData[] | null) => {
  return suggestedJournals && suggestedJournals.length > 0
}

export const reactKey = (obj: Record<string, unknown>): string =>
  uuidv5(JSON.stringify(obj), "00000000-0000-0000-0000-000000000000")

export const truncateText = ({
  limit,
  text
}: {
  limit: number,
  text: string
}) => {
  if (text.length > limit) {
    const textWithCharacterLimit = text.substring(0, limit) 
    const textWithWordLimit = textWithCharacterLimit.split(" ")
    const lastWord = textWithWordLimit[textWithWordLimit.length - 1]

    if (lastWord.length > 10) {
      return `${textWithCharacterLimit.substring(0, limit - 1)}${ellipsis}` // - 1 for ellipsis
    } else {
      return `${textWithCharacterLimit.substring(0, limit - lastWord.length - 1)}${ellipsis}` // - 1 for ellipsis
    }
  } else {
    return text
  }
}
