import React from "react";

import { buttonLinkCSS } from "./BL.css";
import { ButtonLinkInside } from "./BLInside";
import { TLink } from "./BL.types";

export const HashLink = React.forwardRef(
  (
    { 
      analyticsRegion,
      analyticsName,
      children,
      forwardCSS,
      href,
      icon,
      styleType,
      testId,
      ...rest
    }: TLink,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    return (
      <a
        {...rest}
        css={[buttonLinkCSS({styleType: styleType || "text"}), forwardCSS]}
        data-aa-region={analyticsRegion}
        data-aa-name={analyticsName}
        data-testid={testId}
        href={href}
        ref={ref}
      >
        <ButtonLinkInside icon={icon}>
          {children}
        </ButtonLinkInside>
      </a>
    )
  }
);
