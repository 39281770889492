import React, { useReducer } from "react"

import { useFocusRef } from "../lib"
import { Button } from "../generic"
import * as css from "./AbstractInfo.css"

export const AbstractInfo = ({
  abstract,
  describedBy
}: {
  abstract: string,
  describedBy: "current-abstract" | "new-abstract"
}) => {
  const [isToggledOpen, dispatchToggle] = useReducer((state) => {
    return !state
  }, false)

  const focusRef = useFocusRef<HTMLParagraphElement>({
    documentCookie: document.cookie,
    dependencies: [isToggledOpen],
    shouldFocus: isToggledOpen
  })

  const toggledClass = isToggledOpen? "open" : "closed"

  return (
    <div css={css._abstractContainerCSS}>
      <p
        className={toggledClass}
        css={css._abstractCSS}
        data-testid="abstract"
        ref={focusRef}
        tabIndex={-1}
      >
        {abstract}
      </p>
      <Button
        aria-describedby={describedBy}
        aria-expanded={isToggledOpen}
        forwardCSS={css._abstractToggleButton}
        icon={{name: isToggledOpen ? "navigate-up" : "navigate-down", position: "after"}}
        onClick={dispatchToggle}
        styleType="text"
        testId="toggle-abstract-details"
      >
        Show {isToggledOpen ? "less" : "more"}
      </Button>
    </div>
  )
}

export default AbstractInfo