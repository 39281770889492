import { css } from "@emotion/react";
import { _minMediaCSS } from "../lib";

export const _openAccessInfoCSS = css`
  max-width: none;
  padding: 0;
  list-style: none;

  grid-row-gap: var(--column-gap);
`;

export const _openAccessItemCSS = css`
  ${_minMediaCSS("xs")} {
    grid-column: span 3;
  }
  ${_minMediaCSS("s")} {
    grid-column: span 3;
  }
  ${_minMediaCSS("m")} {
    &:nth-of-type(1) {
      grid-column: span 2;
    }
    &:nth-of-type(2) {
      grid-column: span 3;      
    }
    &:only-of-type {
      grid-column: span 4;
    }
  }
  ${_minMediaCSS("xl")} {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      grid-column: span 3;
    }
    &:only-of-type {
      grid-column: span 4;
    }
  }
`

export const _openAccessOpenAccessBadgeCSS = css`
  font-size: var(--font-size-xxs);
  background: rgb(var(--es-color-tertiary-yellow3-rgb));
  color: white;
`

export const _openAccessSubscriptionBadgeCSS = css`
  font-size: var(--font-size-xxs);
  background: rgb(var(--es-color-grey6-rgb));
  color: white;
`

export const _openAccessCostSummaryCSS = css`
  display: block;
  margin-top: calc(var(--row-gap) / 2);
  font-size: var(--font-size-l);
`

export const _openAccessCostDetailsCSS = css`
  display: block;
  margin-top: calc(var(--row-gap) / 2);
  font-size: var(--font-size-s);
`
