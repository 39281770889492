import { css } from "@emotion/react";

import { _minMediaCSS } from "../lib";

export const _viewContainerCSS = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const _viewMainCSS = css`
  flex: 1;
  width: calc(var(--column-gap) + var(--page-width) + var(--column-gap));
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 0 var(--spacing-xxl) 0;
`;

export const _viewTitleContainerCSS = css`
  display: grid;
  grid-gap: var(--row-gap) var(--column-gap);
  ${_minMediaCSS("m")} {
    grid-template-columns: max-content 1fr;
  }
`;

export const _viewTitleCSS = css`
  margin: 0;
  font-size: var(--font-size-l);
  font-weight: bold;
  ${_minMediaCSS("m")} {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`;

export const _viewSubTitleCSS = css`
  margin: 0;
  font-size: var(--font-size-s);
  ${_minMediaCSS("m")} {
    grid-column: 1 / span 2;
    grid-row: 2 / 2;
  }
`;

export const _viewHeaderOfferTypeCSS = css`
  margin: 0;
  max-width: none;
  ${_minMediaCSS("m")} {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    justify-content: flex-end;
  }
`;
