import React, { useEffect } from "react"
import { Outlet } from "react-router-dom"

import * as lib from "../lib"
import { LoadingIndicator } from "../generic"
import ErrorView from "../ErrorView"

const StartView = () => {
  const { submissionUuid } = lib.useUrl()

  if (!submissionUuid) {
    return (
      <ErrorView
        errorType={lib.AppErrorType.INVALID_URL}
      />
    )
  }

  const { isLoading, data, error } = lib.useSubmissionDataFromUuid(submissionUuid)

  const submissionData = data?.data

  const [offerStatusUpdate, dispatchOfferStatusUpdate] = lib.useOfferStatusUpdate({
    declinedOn: submissionData?.declinedOn || null,
    status: submissionData?.status || null,
    transfer: submissionData?.transfer || null
  })
  
  useEffect(() => {
    dispatchOfferStatusUpdate({
      action: lib.StatusUpdateTypes.DONT_GO_BACK,
      declinedOn: submissionData?.declinedOn || null,
      status: submissionData?.status || null,
      transfer: submissionData?.transfer || null
    })
  }, [submissionData])

  if (isLoading) {
    return <LoadingIndicator />
  } else if (submissionData) {
    return (
      <Outlet context={{
        dispatchOfferStatusUpdate,
        submissionData: offerStatusUpdate.status !== null ? {
          ...submissionData,
          ...offerStatusUpdate as {
            status: lib.TOfferStatus,
            transfer: lib.TTransferData | null
          }
        } : submissionData,
      } as lib.TViewContextProps} />
    )
  } else {
    const { errorType, errorDetails } = lib.handleApiError(error)
    return (
      <ErrorView
        errorType={errorType}
        errorDetails={errorDetails}
        submissionData={submissionData}
      />
    )
  }
}

export default StartView