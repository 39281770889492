import { css } from "@emotion/react";

export const _abstractContainerCSS = css`
  margin-top: var(--row-gap);
  --margin: var(--spacing-xs);
`

// https://css-tricks.com/almanac/properties/l/line-clamp/
export const _abstractCSS = css`
  font-size: var(--font-size-s);
  &.closed {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
`

export const _abstractToggleButton = css`
  font-size: var(--font-size-s);
`
