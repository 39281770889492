import React from "react"

import * as lib from "../lib"

import ViewContainer from "../ViewContainer"

import { 
  ConfirmTransferForm,
  TransferFailedMessage,
  TransferConfirmedMessage,
  LegacyAwaitingAuthForm,
  TransferSucceededMessage,
  ConfirmDeclineForm,
  RescindedMessage,
  SuggestionsDeclinedMessage,
  SuggestionsExpiredMessage,
} from "./StatusMessages"

import ErrorView from "../ErrorView"

const StatusView = ({
  dispatchOfferStatusUpdate,
  submissionData
}: lib.TViewContextProps) => {
  const { 
    declinedOn,
    status,
    submissionUuid,
    title: manuscriptTitle,
    transfer
  } = submissionData

  const focusRef = lib.useFocusRef<HTMLHeadingElement>({
    documentCookie: document.cookie,
    dependencies: [status],
    shouldFocus: true
  })

  const getStatusMessageConfig = () => {
    if (
      lib.isOfferStatusLegacyAwaitingAuthentication(status) && transfer
    ) {
      return {
        htmlTitle: `Transfer failed`,
        content: () => (
          <LegacyAwaitingAuthForm
            dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
            journalTitle={transfer.targetJournal.title}
            ref={focusRef}
          />
        )
      }
    } else if (
      lib.isOfferStatusAwaitingConfirmTransferFromUser(status) && transfer
    ) {
      return {
        htmlTitle: "Confirm transfer",
        topTitle: "Confirm transfer",
        content: () => (
          <ConfirmTransferForm
            dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
            ref={focusRef}
            submissionUuid={submissionUuid}
            transfer={transfer}
          />
        )
      }
    } else if (
      lib.isOfferStatusAwaitingConfirmTransferFromApi(status) && transfer ||
      lib.isOfferStatusTransferConfirmed(status) && transfer
    ) {
      return {
        htmlTitle: `Transfer confirmed`,
        content: () => (
          <TransferConfirmedMessage
            ref={focusRef}
            submissionUuid={submissionUuid}
            transfer={transfer}
          />
        )
      }
    } else if (
      lib.isOfferStatusTransferSucceeded(status) && transfer ||
      lib.isOfferStatusAccepted(status)
    ) {
      return {
        htmlTitle: `Complete your submission`,
        content: () => (
          <TransferSucceededMessage
            ref={focusRef}
            submissionUuid={submissionUuid}
            transfer={transfer}
          />
        )
      }
    } else if (
      lib.isOfferStatusTransferFailed(status) && transfer
    ) {
      return {
        htmlTitle: `Transfer failed`,
        content: () => (
          <TransferFailedMessage
            journalTitle={transfer.targetJournal.title}
            ref={focusRef}
          />
        )
      }
    } else if (
      lib.isOfferStatusAwaitingConfirmDeclineFromUser(status) && declinedOn
    ) {
      return {
        htmlTitle: "Confirm decline all",
        topTitle: "Confirm decline all",
        content: () => (
          <ConfirmDeclineForm
            declineDate={declinedOn}
            dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
            ref={focusRef}
            submissionUuid={submissionUuid}
          />
        )
      }
    } else if (
      lib.isOfferStatusAwaitingConfirmDeclineFromApi(status) && declinedOn ||
      lib.isOfferStatusDeclined(status)
    ) {
      return {
        htmlTitle: `Suggestions declined`,
        content: () => (
          <SuggestionsDeclinedMessage 
            declinedOn={declinedOn}
            ref={focusRef}
          />
        )
      }
    } else if (
      lib.isOfferStatusExpired(status)
    ) {
      return {
        htmlTitle: `Suggestions expired`,
        content: () => (
          <SuggestionsExpiredMessage ref={focusRef} />
        )
      }
    } else if (
      lib.isOfferStatusRescinded(status)
    ) {
      return {
        htmlTitle: `Suggestions no longer available`,
        content: () => (
          <RescindedMessage ref={focusRef} />
        )
      }
    }
  }

  const statusMessageConfig = getStatusMessageConfig()
  
  if (statusMessageConfig) {
    const { htmlTitle, topTitle, content } = statusMessageConfig
    
    return (
      <ViewContainer
      viewName="status"
      htmlTitle={`${htmlTitle} for ${manuscriptTitle}`}
      topTitle={topTitle || "Status update for"}
      submissionData={submissionData}
      >
        {content()}
      </ViewContainer>
    )
  } else {
    return (
      <ErrorView
        errorType={lib.AppErrorType.JAVASCRIPT_ERROR}
        submissionData={submissionData}
      />
    )
  }
}

export default StatusView