import React, { useState } from "react"
import { useOutletContext } from "react-router-dom"

import { TViewContextProps, handleApiError } from "../lib"

import EvaluateConsent from "./EvaluateConsent"
import ErrorView from "../ErrorView"
import EvaluateCompleted from "./EvaluateCompleted"
import { useEvaluateManuscript } from "../lib/useEvaluateManuscript"
import EvaluateInProgress from "./EvaluateInProgress"

export type TEvaluateStatus = "AWAITING_CONSENT" | "IN_PROGRESS" | "COMPLETED"

export type TSetEvaluateStatus = React.Dispatch<React.SetStateAction<TEvaluateStatus>>

const EvaluateViewRouter = () => {
  const {
    dispatchOfferStatusUpdate,
    submissionData,
  } = useOutletContext<TViewContextProps>()

  const {
    submissionUuid,
  } = submissionData

  const [evaluateStatus, setEvaluateStatus] = useState<TEvaluateStatus>("AWAITING_CONSENT")

  const { isLoading, data, error } = useEvaluateManuscript({
    submissionUuid,
    hasConsent: evaluateStatus === "IN_PROGRESS"
  })

  if (evaluateStatus === "AWAITING_CONSENT") {
    return (
      <EvaluateConsent
        dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
        setEvaluateStatus={setEvaluateStatus}
        submissionData={submissionData}
      />
    )
  } else if (isLoading) {
    return (
      <EvaluateInProgress
        dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
        submissionData={submissionData}
      />

    )
  } else if (data?.data) {
    return (
      <EvaluateCompleted
        abstractData={data?.data}
        dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
        submissionData={submissionData}
      />
    )
  } else {
    const { errorType, errorDetails } = handleApiError(error)
    return (
      <ErrorView
        errorType={errorType}
        errorDetails={errorDetails}
        submissionData={submissionData}
      />
    )
  }
}

export default EvaluateViewRouter
