import React from "react";

import { Button, Icon, TIconName } from "..";
import { toggleMenuButtonCSS } from "./ToggleMenu.css";
import { TButton } from "../ButtonsAndLinks/BL.types";

interface TToggleMenuButtonProps extends TButton {
  "aria-label": string
  iconName: TIconName
}

export const ToggleMenuButton = ({
  className,
  forwardCSS,
  iconName,
  onClick,
  testId,
  ...rest
}: Omit<TToggleMenuButtonProps, "children">) => {
  return (
    <Button
      {...rest}
      className={className}
      forwardCSS={[toggleMenuButtonCSS, forwardCSS]}
      onClick={onClick}
      styleType="nav"
      testId={testId}
    >
      <Icon name={iconName} />
    </Button>
  );
};
