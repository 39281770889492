import React, { ReactNode, useEffect } from "react";

import * as lib from "../lib";
import { PendoFloatingFeedback } from "../generic";

import { _viewMainCSS, _viewContainerCSS } from "./ViewContainer.css";
import { translateViewNameToAAPageName } from "./ViewContainer.utils";
import {
  isErrorView,
  TErrorView,
  isDataView,
  isDetailView,
  isStatusView,
  isSuggestionsView,
  TDataView,
  isEvaluateView,
} from "./ViewContainer.types";

import ApplicationBanner from "../ApplicationBanner";
import ApplicationFooter from "../ApplicationFooter";
import ViewInfo from "./ViewInfo";
import ManuscriptInfo from "../ManuscriptInfo";

type TViewContainer = TDataView | TErrorView;

const ViewContainer = (props: TViewContainer) => {
  useEffect(() => {
    const { htmlTitle, submissionData, viewName } = props;

    lib.setHTMLTitle(htmlTitle);

    if (isDataView(props) && submissionData) {
      const aaPageName = translateViewNameToAAPageName({
        viewName,
        status: submissionData.status,
      });

      lib.activateTarget(aaPageName);

      if (isSuggestionsView(props)) {
        lib.trackNavigation({
          ...submissionData,
          aaPageName,
          currentJournal: {
            issn: submissionData.feederJournal.issn,
            title: submissionData.feederJournal.title,
          },
        });
      }

      if (isDetailView(props)) {
        lib.trackNavigation({
          ...submissionData,
          aaPageName,
          currentJournal: {
            issn: props.suggestedJournal.issn,
            title: props.suggestedJournal.title,
          },
        });
      }

      if (isStatusView(props) || isEvaluateView(props)) {
        const { transfer } = submissionData;

        const currentJournal = transfer?.targetJournal
          ? {
              issn: transfer.targetJournal.issn,
              title: transfer.targetJournal.title,
            }
          : {
              issn: submissionData.feederJournal.issn,
              title: submissionData.feederJournal.title,
            };

        lib.trackNavigation({ ...submissionData, aaPageName, currentJournal });
      }
    }
    
    if (isErrorView(props)) {
      lib.trackErrorView({
        ...submissionData,
        errorType: props.errorType,
        errorDetails: props.errorDetails,
      });
    }
  }, [props]);

  let mainContent: ReactNode = null;

  if (isDataView(props) && props.submissionData) {
    const {
      children,
      viewName,
      topTitle,
      topSubtitle,
      submissionData: {
        feederJournal,
        offerType,
        pubDNumber,
        title: manuscriptTitle,
      },
    } = props;

    mainContent = (
      <>
        {isDetailView(props) && props.navigation}
        <ViewInfo
          viewName={viewName}
          viewTitle={topTitle}
          viewSubtitle={topSubtitle}
          offerType={offerType}
        />
        <ManuscriptInfo
          isMainTitle={viewName === "suggestions"}
          manuscriptTitle={manuscriptTitle}
          feederJournalTitle={feederJournal.title}
          pubDNumber={pubDNumber}
        />
        {children}
      </>
    );
  } else {
    mainContent = props.children;
  }

  return (
    <div css={_viewContainerCSS}>
      <ApplicationBanner />
      <main css={_viewMainCSS} id="main-content" role="main" tabIndex={-1}>
        {mainContent}
        {isDataView(props) && props.submissionData && (
          <PendoFloatingFeedback
            aaPageName={translateViewNameToAAPageName({
              viewName: props.viewName,
              status: props.submissionData.status,
            })}
          />
        )}
      </main>
      <ApplicationFooter />
    </div>
  );
};

export default ViewContainer;
