import React from "react"

import { TMergeElementProps } from "../../lib"

import nonsolusElsevierDigital from "../../../../static/images/nonsolus-elsevier.svg"
import relx from "../../../../static/images/relx.svg"

export const Logo = ({
  forwardCSS,
  name = "elsevier",
  testId,
  ...rest
}: TMergeElementProps<"img", { name?: "elsevier" | "relx" }>) => {
  const logoProps = {
    ...rest,
    css: forwardCSS,
    "data-testid": testId
  }

  if (name === "relx") {
    return (
      <img 
        {...logoProps}
        alt="RELX ™"
        src={relx}
        width="90"
        height="21" 
      />
    )
  } else {
    return (
      <img 
        {...logoProps}
        alt="Elsevier"
        src={nonsolusElsevierDigital}
        width="73"
        height="80" 
      />
    )
  }
};
