import React from "react"
import { TMergeElementProps } from "../../lib"
import { Icon, TIconName } from "../Icon"
import * as css from "./Box.css"

type TBoxElements = "article" | "div" | "section"

type TBoxProps = TMergeElementProps<
  "div", // What is ok for a div is probably ok for the other elements
  {
    color?: css.TBoxColor
    children: React.ReactNode | React.ReactNode[]
    element?: TBoxElements
    iconName?: TIconName
    variant?: css.TBoxVariant
    topper?: React.ReactNode | React.ReactNode[]
  }
>

export const Box = ({
  color = "none",
  children,
  element,
  forwardCSS,
  iconName,
  testId,
  topper,
  variant = "none",
  ...rest  
}: TBoxProps) => {
  const BoxElement = element || "div"

  const classNames: string[] = []
  
  classNames.push(`box-${color}`)
  classNames.push(`box-${variant}`)

  if (iconName) {
    classNames.push(`box-with-icon`)
  }

  const className = classNames.join(" ")

  return (
    <BoxElement
      css={[css._boxCSS, forwardCSS]}
      className={className}
      data-testid={testId}
      {...rest}
    >
      {iconName && 
        <Icon forwardCSS={css._boxIconCSS} name={iconName} />
      }
      {topper && 
        <div className="box-topper">
          {topper}
        </div>
      }
      {iconName || topper ?
        <div className="box-content">
          {children}
        </div> : children}
    </BoxElement>
  )
}
