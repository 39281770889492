import React from "react";

import { LoadingBarCSS, LoadingBarContainerCSS } from "./LoadingBar.css";

const LoadingBar = () => (
  <div css={LoadingBarContainerCSS}>
    <div css={LoadingBarCSS} />
  </div>
);

export default LoadingBar;
