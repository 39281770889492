import { css } from "@emotion/react";

export const _betaBadgeCSS = css`
  display: block;
  width: fit-content;
  padding: 0 var(--spacing-xs);
  font-size: var(--font-size-xs);
  background: var(--color-interaction);
  color: white;
`
