import React from "react"
import { TMergeElementProps } from "../../lib"
import { _utilBarContainerCSS } from "./UtilBar.css"

type TUtilBarElements = "article" | "div" | "section"

// No other clean way to handle element type...
type TUtilBarProps = TMergeElementProps<
  "div", // What is ok for a div is probably ok for the other elements
  {
    children: React.ReactNode | React.ReactNode[]
    direction?: "horizontal" | "vertical"
    element?: TUtilBarElements
  }
>

export const UtilBar = ({
  children,
  direction = "horizontal",
  element = "div",
  forwardCSS,
  testId,
  ...rest
}: TUtilBarProps) => {
  const UtilBarElement = element 

  return (
    <UtilBarElement
      className={`utilbar-${direction}`}
      css={[_utilBarContainerCSS, forwardCSS]}
      data-testid={testId}
      {...rest}
    >
      {children}
    </UtilBarElement>
  )
}
