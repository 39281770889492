import { css } from "@emotion/react"
import { _minMediaCSS } from "../lib"

export const _languageBannerWebShopBoxCSS = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, max-content);
  grid-column-gap: var(--column-gap);
  grid-row-gap: var(--row-gap);
  font-size: var(--font-size-s);

  .language-banner-title {
    font-family: Gulliver;
    font-weight: bold;
    font-size: var(--font-size-l);
    & + * { margin-top: 0.25rem; }
  }

  ${_minMediaCSS("s")} {
    grid-template-columns: 1fr max-content;
    grid-template-rows: repeat(2, max-content);
    align-items: center;

    .language-banner-content {
      grid-column: 1 / span 2;
      grid-row: 1 / 1;
    }
  }

  ${_minMediaCSS("l")} {
    align-items: baseline;
    .language-banner-content {
      grid-column: 1 / 1;
    }
    .language-banner-timing {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
      margin-top: 0;
    }
    .language-banner-link {
      grid-column: 2 / 2;
      grid-row: 1 / 1;
      margin-top: 0;
    }
  }
`;

export const _languageBannerWebShopTimingCSS = css`
  display: inline-flex;
  vertical-align: middle;
  gap: var(--spacing-s);
  padding: 0.25rem var(--spacing-s);
  border-radius: 2em;
  background: var(--color-info-light);
  color: var(--color-info-dark);
`;

/* AI Experiment */

export const _languageBannerAISection = css`
  display: none;
  margin: var(--spacing-xl) 0;
`

export const _languageBannerAIBoxCSS = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--column-gap);
  grid-row-gap: var(--row-gap);
  margin: 0;

  .language-banner-icon {
    display: none;
  }

  .language-banner-title {
    font-size: var(--font-size-l);
    & + * { margin: 0; }
  }

  .language-banner-link {
    font-size: var(--font-size-s);
  }

  ${_minMediaCSS("m")} {
    grid-template-columns: max-content 1fr;

    .language-banner-icon {
      display: block;
      width: fit-content;
      height: fit-content;
      padding: 1.125rem;
      border-radius: 3.5rem;
      background-color: rgb(var(--es-color-link-blue-rgb));
      svg {
        width: 3.5rem;
        height: 3.5rem;
      }
    }

    .language-banner-link {
      grid-column: 2 / 2;
      grid-row: 2 / 2;
    }
  }

  ${_minMediaCSS("l")} {
    grid-template-columns: max-content 1fr max-content;

    .language-banner-link {
      grid-column: 3 / 3;
      grid-row: 1 / 1;
    }
  }
`;
