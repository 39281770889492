import React from "react";

import { TMergeElementProps } from "../../lib";
import { iconCSS } from "./Icon.css";
import { TIconName } from "./Icon.types";

export const Icon = ({
  className,
  forwardCSS,
  name,
  ...rest
}: TMergeElementProps<"svg", { name: TIconName }>) => {
  const svgProps = {
    ...rest,
    className,
    css: [iconCSS, forwardCSS],
    role: "presentation",
    xmlns: "http://www.w3.org/2000/svg",
  }

  switch (name) {
    case "alert-solid-circle":
      return (
        <svg {...svgProps} viewBox="0 0 128 128" height="24" width="24">
          <path d="M63 8c-14.692 0-28.502 5.722-38.892 16.11C13.72 34.5 8 48.31 8 63c0 30.326 24.672 55 55 55h.008c14.688 0 28.498-5.72 38.884-16.108C112.28 91.506 118 77.692 118 63c0-30.328-24.672-55-55-55zm6 84H57V80h12v12zm0-41.858L66.398 72h-7.2L57 50.142V34h12v16.142z"/>
        </svg>
      )
    case "check-clipboard":
      return (
        <svg {...svgProps} viewBox="0 0 100 128" height="24" width="19">
          <path d="M92.43 43.43c-9.31 9.31-38.48 38.47-48.02 48.01L25.89 67.87l-7.87 6.18 25.51 32.43L76 74.03V115H12V27H2v98h84V64.03l13.51-13.54c-3.65-3.65-2.11-2.1-7.08-7.07zM43.96 5c-6.72 0-13.44 4-15.5 12h-1.51C23.1 17 20 20.1 20 23.96v14c0 3.86 3.1 7.04 6.96 7.04h34c3.85 0 7.04-3.18 7.04-7.04V37h8v11.58l10-10V27H68v-3.04C68 20.1 64.81 17 60.96 17h-1.51C57.39 9 50.67 5 43.96 5zm0 9.97c3.58 0 6.31 3.13 5.97 6.54L49.41 27H58v8H30v-8h8.5c-.03 0-.55-5.66-.55-6.02 0-3.31 2.69-6.01 6-6.01z"/>
        </svg>
      )
    case "clock":
      return (
        <svg {...svgProps} viewBox="0 0 100 128" height="24" width="19">
          <path d="M55 36H45v32.75l25.39 15.52 5.22-8.54L55 63.14V36m22.85 56.85c-7.44 7.44-17.33 11.54-27.85 11.54-21.72 0-39.39-17.67-39.39-39.39S28.28 25.61 50 25.61 89.39 43.28 89.39 65c0 10.52-4.1 20.41-11.54 27.85zM49.99 16C22.98 16 1 37.98 1 65s21.98 49 49 49c13.09 0 25.39-5.1 34.65-14.35C93.9 90.39 99 78.09 99 65c0-27.02-21.98-49-49.01-49" />
        </svg>
      )
    case "confirmation-solid-circle":
      return (
        <svg {...svgProps} viewBox="0 0 128 128" height="24" width="24">
          <path d="M63 8c-14.692 0-28.502 5.722-38.892 16.11C13.72 34.5 8 48.31 8 63c0 30.326 24.672 55 55 55h.008c14.688 0 28.498-5.72 38.884-16.108C112.28 91.506 118 77.692 118 63c0-30.328-24.672-55-55-55zm-6.374 77.862L35.064 64.298l7.07-7.07L56.626 71.72l29.24-29.242 7.07 7.07-36.31 36.314z"/>
        </svg>
      )
    case "delete":
      return (
        <svg {...svgProps} viewBox="0 0 70 128" height="24" width="14">
          <path d="M68.94 36.12L62 29 35 56 8 29l-7 7 27 27L1 90l7 7 27-27 27 27 7-7-27-27 26.94-26.88" />
        </svg>
      )
    case "duplicate-document":
      return (
        <svg {...svgProps} viewBox="0 0 100 128" height="24" width="19">
          <path d="M82 4H0v100h10V14h72V4M62 84h20V74H52v30h10V84m28 .96L63.06 112H28V32h62v52.96zM18 22v100h49.22L100 89.08V22H18"/>
        </svg>
      )
    case "failed-solid-circle":
      return (
        <svg {...svgProps} viewBox="0 0 128 128" height="24" width="24">
          <path d="M63 8c-14.692 0-28.502 5.722-38.892 16.11C13.72 34.5 8 48.31 8 63c0 30.326 24.672 55 55 55h.008c14.688 0 28.498-5.72 38.884-16.108C112.28 91.506 118 77.692 118 63c0-30.328-24.672-55-55-55zm21.536 69.464-7.07 7.07L63 70.07 48.536 84.534l-7.07-7.07L55.93 63 41.466 48.536l7.07-7.07L63 55.93l14.464-14.464 7.07 7.07L70.07 63l14.466 14.464z"/>
        </svg>
      )
    case "help":
      return (
        <svg {...svgProps} viewBox="0 0 100 128" height="24" width="19">
          <path d="M50 14c-18.99-.28-37.3 11.76-44.82 29.14-7.95 17.45-4.3 39.36 8.96 53.22 12.48 13.75 33.08 19.14 50.71 13.36 18.88-5.76 33.13-23.92 34.06-43.67 1.41-18.84-9.32-37.78-26.1-46.41C65.82 15.93 57.92 13.99 50 14zm0 9.6c17.84-.32 34.64 13.09 38.39 30.52 4.11 16.54-4.05 35.03-19 43.19-15.16 8.88-36 5.78-47.9-7.15-12.36-12.5-14.46-33.5-4.82-48.2C23.71 30.68 36.68 23.46 50 23.6zM51 36c-9.08 0-16 6-16 16h10c0-4 2-8 6-8s6.4 2.48 5.92 6.46C56.18 56.36 45 58.28 45 68.72V72h10v-2c0-7.18 12-9.64 12-20 0-8-6.82-14-16-14zm-6 42v10h10V78H45z" />
        </svg>
      );
    case "info":
      return (
        <svg {...svgProps} viewBox="0 0 104 128" height="24" width="20">
          <path d="M52 12c-13.624 0-26.431 5.306-36.063 14.937C6.304 36.571 1 49.378 1 63c0 28.122 22.878 51 51 51h.008c13.62 0 26.427-5.306 36.059-14.937C97.697 89.429 103 76.622 103 63c0-28.122-22.878-51-51-51zm29.27 80.268c-7.815 7.817-18.208 12.12-29.262 12.12H52c-22.822 0-41.39-18.566-41.39-41.388 0-11.056 4.305-21.45 12.12-29.268C30.55 25.915 40.944 21.61 52 21.61c22.822 0 41.39 18.57 41.39 41.391 0 11.056-4.305 21.449-12.12 29.268zM47 38h10v10H47zm0 16h10v34H47z" />
        </svg>
      );
    case "info-solid-circle":
      return (
        <svg {...svgProps} viewBox="0 0 128 128" height="24" width="24">
          <path d="M63 8c-14.692 0-28.502 5.722-38.892 16.11C13.72 34.5 8 48.31 8 63c0 30.326 24.672 55 55 55h.008c14.688 0 28.498-5.72 38.884-16.108C112.28 91.506 118 77.692 118 63c0-30.328-24.672-55-55-55zm5 84H58V52h10v40zm0-48H58V34h10v10z"/>
        </svg>
      )
    case "left":
      return (
        <svg {...svgProps} viewBox="0 0 104 128" height="24" width="20">
          <path d="M104 60H19.14L59.4 19.74l-7.08-7.06L0 65l52.32 52.32 7.08-7.06L19.14 70H104V60"/>
        </svg>
      );
    case "link":
      return (
        <svg {...svgProps} viewBox="0 0 104 128" height="24" width="20">
          <path d="M37.92 84.13l-7.05-7.05 34.65-34.66 7.05 7.06-34.65 34.65M96.9 27.37l-9.28-9.28C83.7 14.16 78.46 12 72.88 12s-10.82 2.16-14.74 6.09l-6.86 6.86c-3.93 3.93-6.09 9.16-6.09 14.74 0 4.02 1.14 7.86 3.23 11.16l7.39-7.39c-.43-1.19-.69-2.45-.69-3.77 0-2.92 1.13-5.67 3.17-7.72l6.87-6.86c2.05-2.05 4.79-3.18 7.72-3.18s5.67 1.13 7.72 3.18l9.28 9.28c4.26 4.26 4.26 11.19 0 15.45l-6.86 6.86c-2.05 2.05-4.79 3.18-7.72 3.18-1.27 0-2.49-.25-3.65-.66l-7.41 7.41a20.72 20.72 0 0 0 11.06 3.18c5.58 0 10.82-2.16 14.74-6.09l6.86-6.86c8.13-8.13 8.13-21.36 0-29.49M45.81 94.02l-6.88 6.87a10.899 10.899 0 0 1-7.74 3.18c-2.94 0-5.69-1.13-7.74-3.18l-9.31-9.28c-4.26-4.26-4.26-11.19 0-15.45l6.88-6.86c2.06-2.05 4.8-3.18 7.74-3.18 1.31 0 2.58.26 3.77.69l7.41-7.39a20.86 20.86 0 0 0-11.18-3.23c-5.59 0-10.84 2.16-14.77 6.09l-6.88 6.86c-8.15 8.13-8.15 21.36 0 29.49l9.3 9.28c3.93 3.93 9.18 6.09 14.78 6.09 5.59 0 10.84-2.16 14.77-6.09l6.88-6.86c3.94-3.93 6.11-9.17 6.11-14.75 0-3.98-1.13-7.78-3.19-11.06l-7.42 7.4c1.37 3.85.55 8.31-2.53 11.38" />
        </svg>
      );
    case "menu":
      return (
        <svg 
          {...svgProps} viewBox="0 0 40 24"  height="24" width="40">
          <path d="M0 18h40v2H0zm0-7h40v2H0zm0-7h40v2H0z"></path>
        </svg>
      );
    case "navigate-down":
      return (
        <svg {...svgProps} viewBox="0 0 92 128" height="24" width="18">
          <path d="M1 51l7-7 38 38 38-38 7 7-45 45z" />
        </svg>
      );
    case "navigate-left":
      return (
        <svg {...svgProps} viewBox="0 0 54 128" height="24" width="11">
          <path d="M1 61l45-45 7 7-38 38 38 38-7 7z" />
        </svg>
      );
    case "navigate-right":
      return (
        <svg {...svgProps} viewBox="0 0 54 128" height="24" width="11">
          <path d="M1 99l38-38L1 23l7-7 45 45-45 45z" />
        </svg>
      );
    case "navigate-up":
      return (
        <svg {...svgProps} viewBox="0 0 92 128" height="24" width="18">
          <path d="M46 46L8 84l-7-7 45-45 45 45-7 7z" />
        </svg>
      );
    case "right":
      return (
        <svg {...svgProps} viewBox="0 0 104 128" height="24" width="20">
          <path d="M43.96 19.74L84.22 60H0v10h84.22l-40.26 40.26 7.08 7.06L103.36 65 51.04 12.68z"/>
        </svg>
      );
    case "send":
      return (
        <svg {...svgProps} viewBox="0 0 125 128" height="24" width="24">
          <path d="M113.35 14.88L1.81 37.61l25.11 22.76 9.03-5.32-12.79-11.58L97 28.42 33 66.1v50.57l23.01-18.24-7.57-6.76L43 95.98v-19.9l36.34 32.4 43.56-86.17c-2.33-1.81-3.09-2.41-9.55-7.43zm-8.18 20.33l-28.9 57.15-27.12-24.17 56.02-32.98z"/>
        </svg>
      );
    case "up-left":
      return (
        <svg {...svgProps} viewBox="0 0 78 128" height="24" width="15">
          <path d="M76.62 95.5L17.12 36H74V26H0v74h10V43.02l59.55 59.56 7.07-7.08" />
        </svg>
      );
    case "up-right":
      return (
        <svg {...svgProps} viewBox="0 0 78 128" height="24" width="14.625">
          <path d="M4 36h57.07L1.57 95.5l7.07 7.08L68 43.22V100h10V26H4z" />
        </svg>
      );
  }
};
