import React, { useRef } from "react"

import * as lib from "../lib"

import ViewContainer from "../ViewContainer"
import { DeclineAll } from "./DeclineAll"
import { formatDate } from "../lib"
import SuggestionsList from "./SuggestionsList"

const SuggestionsView = ({
  dispatchOfferStatusUpdate,
  submissionData
}: lib.TViewContextProps) => {
  const declinedOnRef = useRef<string>(formatDate(Date.now()))

  const {
    suggestedJournals,
    title: manuscriptTitle,
  } = submissionData

  const suggestionCountText = `${suggestedJournals.length} ${
    suggestedJournals.length === 1 ? "suggestion" : "suggestions"
  }`

  return (
    <ViewContainer
      viewName="suggestions"
      htmlTitle={`${suggestionCountText} for ${manuscriptTitle}`}
      topTitle="Transfer suggestions"
      topSubtitle={`You have ${suggestionCountText} for this manuscript:`}
      submissionData={submissionData}
    >
      <SuggestionsList suggestedJournals={suggestedJournals} />
      {lib.isRejectWithAISuggestions(submissionData.offerType) &&
        <DeclineAll
          onClick={() => {
            lib.trackMenuButtonClick("declineAllButton")
            dispatchOfferStatusUpdate({
              action: lib.StatusUpdateTypes.REQUEST_DECLINE,
              declinedOn: declinedOnRef.current
            })
          }}
        />
      }
    </ViewContainer>
  )
}

export default SuggestionsView
