import { css } from "@emotion/react";
import { _minMediaCSS } from "../lib";

export const _instructionsContent = css`
  font-size: var(--font-size-s);
`

export const _transferSummaryLockupCSS = css`
  ${_minMediaCSS("s")} {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0 var(--column-gap);
  }
`

export const _transferSummaryBoxCSS = css`
  & + & {
    margin-top: var(--spacing-l);
    padding-top: var(--spacing-l);
    border-top: 1px solid var(--color-border);
  }
`

export const _transferSummaryIllustrationCSS = css`
  grid-column: 1 / 1;
  grid-row: 1 / 1;  
  ${_minMediaCSS("m")} {
    align-self: center;
    align-items: center;
  }
  ${_minMediaCSS("l")} {
    display: grid;
    grid-gap: 0 var(--column-gap);
    grid-template-columns: repeat(3, max-content);
  }
`

export const _journalCoverCSS = css`
  display: none;
  ${_minMediaCSS("s")} {
    display: block;
  }  
`

export const _manuscriptPictoCSS = css`
  display: none;
  ${_minMediaCSS("l")} {
    display: block;
    width: ${120/16}rem;
    aspect-ratio: 3/4;
    object-fit: cover;
  }
`

export const _transferArrowCSS = css`
  display: none;
  ${_minMediaCSS("l")} {
    display: block;
    color: var(--color-on-surface-dim);
  }
`

export const _transferSummaryButtonsCSS = css`
  font-size: var(--font-size-m);
`
