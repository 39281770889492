import axios from "axios";

import { 
  AppErrorType,
  TAppError
} from "./types";

export const handleApiError = (error: unknown): TAppError => {
  if (axios.isAxiosError(error)) {
    const response = error && error.response;

    if (response) {
      const { status } = response;
      // 404
      if (status === 404) {
        return {
          errorType: AppErrorType.SUBMISSION_NOT_FOUND
        }
      // Other bad reponse
      } else {
        return {
          errorType: AppErrorType.API_ERROR,
          errorDetails: status
        }
      }
    // No response
    } else {
      return {
        errorType: AppErrorType.NETWORK_UNAVAILABLE
      };
    }
  // Not an axios error
  } else {
    return {
      errorType: AppErrorType.JAVASCRIPT_ERROR,
      errorDetails: error instanceof Error ? { name: error.name, message: error.message } : undefined
    };
  }
};
