import React from "react";
import { InternalLink } from "../generic";

const BackToTransferStatusLink = () => {
  return (
    <InternalLink
      analyticsName="ah-goto-transfer-status"  
      analyticsRegion="ah-evaluate"
      href="../status"
      styleType="secondary"
      testId="back-to-transfer-status-link"
    >
      Back to transfer status
    </InternalLink>
  );
};

export default BackToTransferStatusLink;
