import { css } from "@emotion/react";

export const listOfTermsListCSS = css`
  display: flex;
  flex-wrap: wrap;
  gap: var(--row-gap) var(--column-gap);
  padding: 0;
  list-style-type: none;
`;

export const listOfTermsItemCSS = css`
  margin: 0;
  padding: ${6 / 16}em 1em;
  border: 1px solid var(--color-on-surface);
  border-radius: 20px;
  font-size: var(--font-size-s);
`;
