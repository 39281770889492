import React from "react"

import {
  BetaBadge,
  Box,
  Icon,
  InternalLink,
} from "../generic"

import * as css from "./LanguageBanner.css"

const LanguageBannerAI = ({
  position,
  submissionUuid
}: {
  position: "before-main-heading" | "after-main-heading"
  submissionUuid: string
}) => {
  return (
    <section
      data-target="language-banner-experiment"
      css={css._languageBannerAISection}>
      <BetaBadge id="language-banner-beta" />
      <Box 
        forwardCSS={css._languageBannerAIBoxCSS}
        color="promo"
        variant="background"
      >
        <div className="language-banner-icon">
          <Icon name="check-clipboard" />
        </div>
        <div>
          {position === "after-main-heading" ?           
            <h2 
              aria-describedby="language-banner-beta"
              className="language-banner-title"
              data-testid="language-banner-title"
            >
              <strong>
                Is your abstract written in high quality English?
              </strong>
            </h2> : 
            <p
              aria-describedby="language-banner-beta"
              className="language-banner-title"
              data-testid="language-banner-title"
            >
              <strong>
                Is your abstract written in high quality English?
              </strong>
            </p>
          }
          <p className="language-banner-text">
            In less than a minute, we can evaluate and suggest edits to the language and grammar that you have used.
          </p>
        </div>
        <div className="language-banner-link">
          <InternalLink 
            analyticsRegion="ah-status"
            analyticsName="ah-goto-language-editing"
            icon={{name: "navigate-right", position: "after"}}
            href={`/transfers/${submissionUuid}/evaluate`}
            styleType="primary"
            testId="language-editing-link"
          >
            Get your free evaluation
          </InternalLink>
        </div>
      </Box>
    </section>
  )
}

export default LanguageBannerAI
