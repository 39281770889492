import React from "react";

import { ButtonLinkInside } from "./BLInside";
import { buttonLinkCSS } from "./BL.css";
import { TButton } from "./BL.types";

export const Button = React.forwardRef(
  (
    {
      children,
      onClick,
      forwardCSS,
      icon,
      styleType,
      type,
      testId,
      ...rest
    }: TButton,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <button
        {...rest}
        css={[buttonLinkCSS({styleType: styleType || "primary"}), forwardCSS]}
        data-testid={testId}
        onClick={onClick}
        ref={ref}
        type={type || "button"}
      >
        <ButtonLinkInside icon={icon}>
          {children}
        </ButtonLinkInside>
      </button>
    );
  }
);
