import {
  isInvalidUrlError,
  isNetworkUnavailableError,
  isSubmissionNotFoundError,
  TAppErrorType
 } from "../lib";

export const errorMessages = (errorType: TAppErrorType) => {
  let title: string
  let content: string

  if (
    isSubmissionNotFoundError(errorType)
  ) {
    // Submission not found for correctly formatted query string or uuid
    title = "Transfer suggestions not found"
    content = "We're sorry, our system could not find any suggestions for your manuscript. There may be a delay. Please try again later."
  } else if (
    isNetworkUnavailableError(errorType)
  ) {
    // Internet problem, it happens
    title = "Network unavailable"
    content = "Please check your Internet connection and try again."
  } else if (
    isInvalidUrlError(errorType)
  ) {
    // Usually garbage or a query string / uuid formatting problem
    title = "Page not found"
    content = "Please check the URL and try again."
  } else {
    // Submission withg no suggestions, API error or javascript error
    title = "There is a problem with our system"
    content = "We're sorry, we cannot load this page at the moment. Please try again later."
  }

  return { title, content }
}
