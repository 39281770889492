import { css } from "@emotion/react";

export const _badgeCSS = css`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 0.75em;
  border-radius: 2px;
  font-size: var(--font-size-s);
`
