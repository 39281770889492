import React, { forwardRef } from "react";

import * as lib from "../lib"
import { Box, Button, UtilBar } from "../generic"
import * as css from "./StatusView.css"
import { TTransferData } from "../lib";
import { statusMessageTitleProps, statusMessageContentProps, AssistanceMessage } from "./Message.vars";
import MoreTransferOptions from "../MoreTransferOptions";
import SubmissionInstructions from "./SubmissionInstructions";
import LanguageBannerWebShop, { LanguageBannerAI } from "../LanguageBanner";
import LoginToJournalLink from "./LoginToJournalLink";
import JournalCard from "../JournalCard";

export const ConfirmTransferForm = forwardRef(({
  dispatchOfferStatusUpdate,
  submissionUuid,
  transfer
}: {
  dispatchOfferStatusUpdate: lib.TViewContextProps["dispatchOfferStatusUpdate"]
  submissionUuid: string
  transfer: TTransferData
}, ref: React.Ref<HTMLHeadingElement>) => {
  const onCancelTransfer = () => {
    lib.trackFormButtonClick("cancelTransfer")
    dispatchOfferStatusUpdate({
      action: lib.StatusUpdateTypes.CANCEL
    })
  }

  const confirmTransfer = lib.useConfirmTransfer({
    submissionUuid,
    acronym: transfer.targetJournal.acronym,
    onMutate: () => {
      lib.trackFormButtonClick("confirmTransfer")
      dispatchOfferStatusUpdate({
        action: lib.StatusUpdateTypes.REQUEST_TRANSFER,
        transfer
      })
    },
    onError: (error: unknown) => {
      dispatchOfferStatusUpdate({
        action: lib.StatusUpdateTypes.THROW_TRANSFER_ERROR,
        transfer
      })

      const handledApiError = lib.handleApiError(error)
      lib.trackApiError({
        action: "transfer_confirm",
        status: handledApiError.errorDetails || "unknown"
      })
    }
  })

  const onConfirmTransfer = () => confirmTransfer.mutate()

  return (
    <>   
      <Box>      
        <h1 {...statusMessageTitleProps} ref={ref}>
          Transfer your manuscript to <em>{transfer.targetJournal.title}?</em>
        </h1>
        <p {...statusMessageContentProps}>
          We will transfer your files to <em>{transfer.targetJournal.title}</em>.
          {" "}
          You will receive an email from them with information about completing your submission.
          {" "}
          You will also be able to make revisions.
        </p>
      </Box>
      <JournalCard
        analyticsRegion="ah-status"
        journal={transfer.targetJournal}
      />
      <Box forwardCSS={css._transferSummaryButtonsCSS}>
        <UtilBar>
          <Button
            onClick={onCancelTransfer}
            styleType="secondary"
            testId="cancel-transfer-button"
          >
            Cancel
          </Button>
          <Button 
            onClick={onConfirmTransfer}
            testId="confirm-transfer-button"
          >
            Confirm transfer
          </Button>
        </UtilBar>
      </Box>
    </>
  )
})

export const TransferConfirmedMessage = forwardRef(({
  submissionUuid,
  transfer
}: {
  submissionUuid: string
  transfer: TTransferData;
}, ref: React.Ref<HTMLHeadingElement>) => {
  const { initiatedDate, targetJournal } = transfer;

  return (
    <>    
      <Box 
        color="info"
        iconName="info-solid-circle"
        variant="background" 
      >
        <h1 {...statusMessageTitleProps} ref={ref}>
          Transfer confirmed
        </h1>
        <p {...statusMessageContentProps}>
          Thank you! Transfer of your manuscript to <em>{targetJournal.title}</em> was confirmed on <strong>{initiatedDate}</strong> and is currently in progress.
          {" "}
          You will receive an email from them with information about completing your submission.
          {" "}
          You will also be able to make revisions.
        </p>  
      </Box>
      <JournalCard 
        analyticsRegion="ah-status"
        journal={transfer.targetJournal}
      />
      <LanguageBannerWebShop position="after-main-heading" />
      <LanguageBannerAI position="after-main-heading" submissionUuid={submissionUuid}/>
    </>
  )
})

// Transfer data may not be available if transfer was completed in Editorial Manager
export const TransferSucceededMessage = forwardRef(({
  submissionUuid,
  transfer
}: {
  submissionUuid: string
  transfer: TTransferData | null
}, ref: React.Ref<HTMLHeadingElement>) => {
  return (
    <>  
      <Box>
        <LanguageBannerWebShop position="before-main-heading" />
        <LanguageBannerAI position="before-main-heading" submissionUuid={submissionUuid}/>
        <h1 {...statusMessageTitleProps} ref={ref}>
          <strong>
            Complete your submission to {transfer?.targetJournal ? <em>{transfer.targetJournal.title}</em> : "the journal"}
          </strong>
        </h1>
        {transfer?.targetJournal ? 
          <>
            <p {...statusMessageContentProps}>
              On {transfer?.initiatedDate} your files were sent to <em>{transfer.targetJournal.title}</em> where we started a new submission for you.{" "}
              Please note this submission was created, but not submitted.{" "}
              If you already have an account with <em>{transfer.targetJournal.title}</em>, please sign in to find your new submission.{" "}
              If you do not already have an account, you will receive an email with instructions for signing in.
            </p>
            <LoginToJournalLink 
              analyticsRegion="ah-status"            
              targetJournal={transfer.targetJournal}
              variant="button"
            />
          </>:
          <p {...statusMessageContentProps}>
            Your files have been sent to your chosen journal where we have started a new submission for you.{" "}
            Please note this submission has been created, but not submitted.{" "}
            If you already have an account with the journal, please sign in to find your new submission.{" "}
            If you do not already have an account, you will receive an email with instructions for signing in.{" "}
          </p>
        }
      </Box>
      <SubmissionInstructions 
        analyticsRegion="ah-status"
        targetJournal={transfer?.targetJournal}
      />
    </>
  )
})

export const TransferFailedMessage = forwardRef(({
  journalTitle
}: {
  journalTitle: string;
}, ref: React.Ref<HTMLHeadingElement>) => {
  return (
    <Box 
      color="alert"
      iconName="failed-solid-circle"
      variant="background" 
    >
      <h1 {...statusMessageTitleProps} ref={ref}>
        Transfer failed
      </h1>
      <p {...statusMessageContentProps}>
        We&apos;re sorry, transfer of your manuscript to <em>{journalTitle}</em> failed.
        {" "}
        This is a problem with our system.
      </p>
      <AssistanceMessage />
    </Box>
  )
})

export const LegacyAwaitingAuthForm = forwardRef(({
  dispatchOfferStatusUpdate,
  journalTitle,
}: {
  dispatchOfferStatusUpdate: lib.TViewContextProps["dispatchOfferStatusUpdate"]
  journalTitle: string
}, ref: React.Ref<HTMLHeadingElement>) => {
  const onReturnToSuggestions = () => {
    dispatchOfferStatusUpdate({
      action: lib.StatusUpdateTypes.CANCEL
    })
    lib.trackMenuButtonClick("cancelAwaitingAuthentication")
  }

  return (
      <Box 
        color="alert"
        iconName="failed-solid-circle"
        variant="background" 
      >
        <h1 {...statusMessageTitleProps} ref={ref}>
          Transfer failed
        </h1>
        <p {...statusMessageContentProps}>
          We&apos;re sorry, transfer of your manuscript to <em>{journalTitle}</em> failed. This is a problem with our system. Please return to the list of suggested journals and try again.
        </p>
        <Button
          forwardCSS={css._transferSummaryButtonsCSS}
          icon={{name: "navigate-left", position: "before"}}
          onClick={onReturnToSuggestions}
          testId="return-to-suggestions-button"
        >
          Return to suggested journals
        </Button>
      </Box>
  );
})

export const ConfirmDeclineForm = forwardRef(({
  declineDate,
  dispatchOfferStatusUpdate,
  submissionUuid
}: {
  declineDate: string,
  dispatchOfferStatusUpdate: lib.TViewContextProps["dispatchOfferStatusUpdate"]
  submissionUuid: string
}, ref: React.Ref<HTMLHeadingElement>) => {
  const onCancel = () => {
    lib.trackFormButtonClick("cancelDeclineAll")
    dispatchOfferStatusUpdate({
      action: lib.StatusUpdateTypes.CANCEL
    })
  }

  const declineAll = lib.useDeclineAll({
    submissionUuid,
    onMutate: () => {
      lib.trackFormButtonClick("confirmDeclineAll")
      dispatchOfferStatusUpdate({
        action: lib.StatusUpdateTypes.CONFIRM_DECLINE,
        declinedOn: declineDate
      })
    }
  })

  const onDeclineAll = () => {
    declineAll.mutate(void {
      onError: (error: unknown) => {
        const handledApiError = lib.handleApiError(error)
        lib.trackApiError({
          action: "decline_all_confirm",
          status: handledApiError.errorDetails || "unknown"
        })
      }
    })
  }

  return (
    <>
      <Box>
        <h1 {...statusMessageTitleProps} ref={ref}>
          Decline all transfer suggestions for your manuscript?
        </h1>
        <p {...statusMessageContentProps}>
          You will no longer be able to view the transfer suggestions and you will no longer recieve email reminders for this transfer offer.
        </p>
        <AssistanceMessage />
      </Box>
      <Box forwardCSS={css._transferSummaryButtonsCSS}>
        <UtilBar>
          <Button
            onClick={onCancel}
            styleType="secondary"
            testId="cancel-decline-button"
          >
            Cancel
          </Button>
          <Button 
            onClick={onDeclineAll}
            testId="confirm-decline-button"
          >
            Confirm
          </Button>
        </UtilBar>
      </Box>
    </>
  )
})

// Declined on date may not be available if transfer was declined in Editorial Manager
export const SuggestionsDeclinedMessage = forwardRef(({
  declinedOn
}: {
  declinedOn?: string
}, ref: React.Ref<HTMLHeadingElement>) => {
  return (
    <>
      <Box 
        color="info"
        iconName="info-solid-circle"
        variant="background" 
      >
        <h1 {...statusMessageTitleProps} ref={ref}>
          All transfer suggestions declined
        </h1>
        {declinedOn &&
          <p {...statusMessageContentProps}>
            You declined all transfer suggestions for your manuscript {declinedOn && <>on <strong>{declinedOn}</strong></>}. You will no longer receive email reminders for this transfer offer.
          </p>
        }
        <AssistanceMessage />
      </Box>
      <MoreTransferOptions />
    </>
  )
})

export const SuggestionsExpiredMessage = forwardRef((_, ref: React.Ref<HTMLHeadingElement>) => {
  return (
    <>
      <Box 
        color="info"
        iconName="info-solid-circle"
        variant="background"  
      >
        <h1 {...statusMessageTitleProps} ref={ref}>
          All transfer suggestions expired
        </h1>
        <AssistanceMessage />
      </Box>
      <MoreTransferOptions />
    </>
  )
})

export const RescindedMessage = forwardRef((_, ref: React.Ref<HTMLHeadingElement>) => {
  return (  
    <Box 
      color="info"
      iconName="info-solid-circle"
      variant="background" 
    >
      <h1 {...statusMessageTitleProps} ref={ref}>
        Suggestions no longer available
      </h1>
      <p {...statusMessageContentProps}>
        The transfer suggestions for your manuscript are no longer available because an editor has taken action on the journal site. Please go to the journal site for more information.
      </p>
      <AssistanceMessage />
    </Box>
  )
})
