import { css } from "@emotion/react"

export const _pendoFloatingFeedbackContainerCSS = css`
  position: sticky;
  bottom: 0;
  left: 100%;
  z-index: 10;
  width: fit-content;
  min-height: 4rem;
`

// Warning this is a hack
// Yellow button
// More or less copied from lib/buttonLink.css.ts with !important added to overwrite Pendo's inline styles :|
export const _pendoFloatingFeedbackButtonCSS = css`
  ._pendo-badge {
    cursor: pointer !important;
    position: relative !important;
    bottom: 6px;
    right: 6px;
    display: inline-flex !important;
    gap: 1em !important;
    align-items: center !important;
    padding: calc(0.75em - 2px) calc(1.5em - 4px) !important;
    font-size: inherit !important;
    height: auto !important;
    line-height: var(--line-height) !important;
    border: 2px solid rgb(var(--es-color-tertiary-yellow2-rgb)) !important;
    border-radius: 2px !important;
    white-space: nowrap !important;
    text-decoration: none !important;
    background-color: rgb(var(--es-color-tertiary-yellow2-rgb)) !important;
    color: black !important;
    transition: all 0.2s !important;
    &:after {
      content: url(/images/icon-send.svg);
      width: auto !important;
      height: var(--font-size-s) !important;
      margin-top: -2px !important;
    }
    &:hover {
      background-color: white !important;
      border-color: var(--color-interaction) !important;
      color: black !important;
    }
    &:focus {
      outline: 0 !important;
      box-shadow: 0 0 0 2px white,0 0 0 4px var(--color-interaction) !important;
    }
    &:focus:hover {
      box-shadow: none !important;
    }
    ._pendo-badge-text {
      font-size: inherit !important;
      color: inherit !important;
    }
  }
`
