import React, { useRef } from "react";

import { TMergeElementProps } from "../../lib";

import * as css from "./Tooltip.css"
import { Button } from "../ButtonsAndLinks";
import useTooltipPositionManager from "./useTooltipPositionManager";

const IconCaret = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    css={css.caretCSS}
    aria-hidden="true"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16L0 16" />
      <path d="M 0 0 L 0 16 L 1 15 L 1 0 L 0 0" />
      <path d="M0 16L0 13L13 0L16 0L0 16" />
    </g>
  </svg>
);

export const Tooltip = ({
  children,
  forwardCSS,
  id,
  label
}: TMergeElementProps<"div", {
  children: React.ReactNode | React.ReactNode[],
  id: string
  label: string
}>) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const {targetRef, popupRef, position} = useTooltipPositionManager({tooltipRef});

  return (
    <div ref={tooltipRef} css={[forwardCSS, css.tooltipContainerCSS]} className={`positionx-${position.x} positiony-${position.y}`}>
      <Button 
        ref={targetRef}
        aria-describedby={id}
        className="tooltip-button"
        icon={{ name: "info", position: "after" }}
        styleType="text"
        testId={`${id}-button`}
      >
        {label}
      </Button>
      <div 
        ref={popupRef}
        className="tooltip-content-outer"
        id={id}
        role="tooltip"
      >
        <div className="tooltip-content-inner">
          {children}
          <IconCaret />
        </div>
      </div>
    </div>
  )
}
