import React from "react";

import { supportCenterUrl } from "../lib";
import { ExternalLink } from "../generic";

export const statusMessageTitleProps = {
  "data-testid": "status-message-title",
  tabIndex: -1,
}

export const statusMessageContentProps = {
  "data-testid": "status-message-content",
}

export const AssistanceMessage = () => (
  <p>
    If you have questions or need assistance please contact us via our
    {" "}
    <ExternalLink 
      analyticsRegion="ah-status"
      analyticsName="ah-support-center"
      href={supportCenterUrl}
      styleType="text-underlined"
      testId="support-center-status-link"
    >
      Support Center
    </ExternalLink>
  </p>
)