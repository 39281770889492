import React from "react"
import { Box } from "../generic"
import * as css from "./SubmissionInstructions.css"
import { TSuggestedJournalData } from "../lib";
import LoginToJournalLink from "./LoginToJournalLink";

const SubmissionInstructions = ({
  analyticsRegion,
  targetJournal
}: {
  analyticsRegion: string,
  targetJournal?: TSuggestedJournalData | null
}) => {
  const loginToJournal = targetJournal ? 
    <LoginToJournalLink 
      analyticsRegion={analyticsRegion}           
      targetJournal={targetJournal}
      variant="text"
    /> : "Login to the journal."

  return (
    <Box>
      <h2>
        <strong>
          To complete your submission:
        </strong>
      </h2>
      <ol css={css._instructionsContent}>
        <li>
          {loginToJournal}
        </li>
        <li>
          Find your transferred manuscript under &quot;New Submissions&quot; then &quot;Submissions Sent Back to Author&quot;.
        </li>
        <li>
          Select &quot;Edit submission&quot; under &quot;Action&quot; and fill in all mandatory information.
        </li>
        <li>
          When finished, click &quot;Build PDF for Approval&quot; to complete the submission process and proceed to &quot;Submissions Waiting Approval by Author&quot;.
        </li>
        <li>
          When the PDF build is finished, accept the &quot;Ethics in Publishing Policy&quot; by checking &quot;I accept&quot;.
        </li>
        <li>
          Then select &quot;Approve Submission&quot; under &quot;Action&quot;.
        </li>
      </ol>
      <h2>
        <strong>
          If you want to revise before submitting:
        </strong>
      </h2>
      <p css={css._instructionsContent}>
        Follow the steps 1 and 2 above, then select &quot;Edit submission&quot;, delete the outdated files and upload the revised files before submitting.
      </p>
      <h2>
        <strong>
          If you no longer want to submit your manuscript:
        </strong>
      </h2>
      <p css={css._instructionsContent}>
        Your files will be automatically removed after 90 days if you don&apos;t take further action. You can also remove the files yourself: Follow the steps 1 and 2 above, then select &quot;Remove Submission&quot;. The submission will be permanently removed from the system. Please note this action cannot be undone.
      </p>
    </Box>
  )
}

export default SubmissionInstructions