/*
  The evils of enums in Typescript: https://dev.to/ivanzm123/dont-use-enums-in-typescript-they-are-very-dangerous-57bh
  Best solution: https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
*/

export const OfferStatus = {
  LEGACY_AWAITING_AUTHENTICATION: "AWAITING_AUTHENTICATION",
  ACTIVE: "ACTIVE",
  AWAITING_CONFIRM_TRANSFER_FROM_USER: "AWAITING_CONFIRM_TRANSFER_FROM_USER",
  AWAITING_CONFIRM_TRANSFER_FROM_API: "AWAITING_CONFIRM_TRANSFER_FROM_API",
  CONFIRMED: "INITIATED", // "INITIATED" comes from the api, but "CONFIRMED" matches with messaing in ui
  ACCEPTED: "ACCEPTED",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
  AWAITING_CONFIRM_DECLINE_FROM_USER: "AWAITING_CONFIRM_DECLINE_FROM_USER",
  AWAITING_CONFIRM_DECLINE_FROM_API: "AWAITING_CONFIRM_DECLINE_FROM_API",
  DECLINED: "DECLINED",
  EXPIRED: "EXPIRED",
  RESCINDED: "RESCINDED"
} as const
export type TOfferStatus = typeof OfferStatus[keyof typeof OfferStatus]

export const OrderedOfferStatus = {
  [OfferStatus.LEGACY_AWAITING_AUTHENTICATION]: 0,
  [OfferStatus.ACTIVE]: 1,
  [OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_USER]: 2,
  [OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_API]: 3,
  [OfferStatus.CONFIRMED]: 4,
  [OfferStatus.ACCEPTED]: 5,
  [OfferStatus.SUCCEEDED]: 6,
  [OfferStatus.FAILED]: 7,
  [OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_USER]: 8,
  [OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_API]: 9,
  [OfferStatus.DECLINED]: 10,
  [OfferStatus.EXPIRED]: 11,
  [OfferStatus.RESCINDED]: 12
} as const
export type TOrderedOfferStatus = typeof OrderedOfferStatus[keyof typeof OrderedOfferStatus]

export type TOfferStatusLegacyAwaitingAuthentication = typeof OfferStatus.LEGACY_AWAITING_AUTHENTICATION
export type TOfferStatusActive = typeof OfferStatus.ACTIVE
export type TOfferStatusAwaitingConfirmTransferFromUser = typeof OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_USER
export type TOfferStatusAwaitingConfirmTransferFromApi = typeof OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_API
export type TOfferStatusTransferConfirmed = typeof OfferStatus.CONFIRMED
export type TOfferStatusTransferSucceeded = typeof OfferStatus.SUCCEEDED
export type TOfferStatusTransferFailed = typeof OfferStatus.FAILED
export type TOfferStatusAwaitingConfirmDeclineFromUser = typeof OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_USER
export type TOfferStatusAwaitingConfirmDeclineFromApi = typeof OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_API
export type TOfferStatusDeclined = typeof OfferStatus.DECLINED
export type TOfferStatusExpired = typeof OfferStatus.EXPIRED
export type TOfferStatusAccepted = typeof OfferStatus.ACCEPTED
export type TOfferStatusRescinded = typeof OfferStatus.RESCINDED

export type TOfferStatusDEAR = 
  | TOfferStatusDeclined
  | TOfferStatusExpired
  | TOfferStatusAccepted
  | TOfferStatusRescinded

export type TOfferStatusHasTransferData = 
| TOfferStatusLegacyAwaitingAuthentication
  | TOfferStatusAwaitingConfirmTransferFromUser
  | TOfferStatusAwaitingConfirmTransferFromApi
  | TOfferStatusTransferConfirmed
  | TOfferStatusTransferSucceeded
  | TOfferStatusTransferFailed

export type TOfferStatusFromApi = 
  | TOfferStatusLegacyAwaitingAuthentication
  | TOfferStatusActive
  | TOfferStatusTransferConfirmed
  | TOfferStatusTransferSucceeded
  | TOfferStatusTransferFailed
  | TOfferStatusDEAR

export type TOfferStatusFromUser = 
  | TOfferStatusAwaitingConfirmTransferFromUser
  | TOfferStatusAwaitingConfirmTransferFromApi
  | TOfferStatusAwaitingConfirmDeclineFromUser
  | TOfferStatusAwaitingConfirmDeclineFromApi

// Type guards

export function isOfferStatusActive(
  t: string
): t is TOfferStatusActive {
  return t === OfferStatus.ACTIVE
}

export function isOfferStatusAwaitingConfirmTransferFromUser(
  t: string
): t is TOfferStatusAwaitingConfirmTransferFromUser {
  return t === OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_USER
}

export function isOfferStatusAwaitingConfirmTransferFromApi(
  t: string
): t is TOfferStatusAwaitingConfirmTransferFromUser {
  return t === OfferStatus.AWAITING_CONFIRM_TRANSFER_FROM_API
}

export function isOfferStatusTransferConfirmed(
  t: string
): t is TOfferStatusTransferConfirmed {
  return t === OfferStatus.CONFIRMED
}

export function isOfferStatusTransferSucceeded(
  t: string
): t is TOfferStatusTransferSucceeded {
  return t === OfferStatus.SUCCEEDED
}

export function isOfferStatusTransferFailed(
  t: string
): t is TOfferStatusTransferFailed {
  return t === OfferStatus.FAILED
}

export function isOfferStatusLegacyAwaitingAuthentication(
  t: string
): t is TOfferStatusLegacyAwaitingAuthentication {
  return t === OfferStatus.LEGACY_AWAITING_AUTHENTICATION
}

export function isOfferStatusAwaitingConfirmDeclineFromUser(
  t: string
): t is TOfferStatusDeclined {
  return t === OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_USER
}

export function isOfferStatusAwaitingConfirmDeclineFromApi(
  t: string
): t is TOfferStatusDeclined {
  return t === OfferStatus.AWAITING_CONFIRM_DECLINE_FROM_API
}

export function isOfferStatusDeclined(
  t: string
): t is TOfferStatusDeclined {
  return t === OfferStatus.DECLINED
}

export function isOfferStatusExpired(
  t: string
): t is TOfferStatusExpired {
  return t === OfferStatus.EXPIRED
}

export function isOfferStatusAccepted(
  t: string
): t is TOfferStatusAccepted {
  return t === OfferStatus.ACCEPTED
}

export function isOfferStatusRescinded(
  t: string
): t is TOfferStatusRescinded {
  return t === OfferStatus.RESCINDED
}

export function isOfferStatusDEAR(
  t: string
): t is TOfferStatusDEAR {
  return (
    isOfferStatusDeclined(t) ||
    isOfferStatusExpired(t) ||
    isOfferStatusAccepted(t) ||
    isOfferStatusRescinded(t)
  )
}

export function isOfferStatusFromApi(
  t: string
): t is TOfferStatusFromApi {
  return (
    isOfferStatusLegacyAwaitingAuthentication(t) ||
    isOfferStatusActive(t) ||
    isOfferStatusTransferConfirmed(t) ||
    isOfferStatusTransferSucceeded(t) ||
    isOfferStatusTransferFailed(t) ||
    isOfferStatusDEAR(t)
  )
}

export function isOfferStatusHasTransferData(
  t: string
): t is TOfferStatusHasTransferData {
  return (
    isOfferStatusAwaitingConfirmTransferFromUser(t) ||
    isOfferStatusAwaitingConfirmTransferFromApi(t) ||
    isOfferStatusTransferConfirmed(t) ||
    isOfferStatusTransferSucceeded(t) ||
    isOfferStatusTransferFailed(t) ||
    isOfferStatusLegacyAwaitingAuthentication(t)
  )
}
