import React from "react";

import { TOfferType, useFocusRef } from "../lib";
import { Box } from "../generic";
import OfferTypeIndicator from "../OfferTypeIndicator";
import * as css from "./ViewContainer.css";
import { TViewName } from "./ViewContainer.types";

const ViewInfo = ({
  viewName,
  viewTitle,
  viewSubtitle,
  offerType,
}: {
  viewName: TViewName;
  viewTitle: string;
  viewSubtitle?: string;
  offerType: TOfferType;
}) => {
  const focusRef = useFocusRef<HTMLHeadingElement | HTMLParagraphElement>({
    documentCookie: document.cookie,
    dependencies: [viewTitle],
    shouldFocus: true,
  });

  const Title = viewName === "suggestions" ? "h1" : "p"

  const titleProps = viewName === "suggestions" ? {
    css: css._viewTitleCSS,
    "data-testid": "view-title",
    id: "view-title",
    ref: focusRef,
    tabIndex: -1,
  } : {
    css: css._viewTitleCSS,
    id: "view-title",
    "data-testid": "view-title",
  };

  const showsOfferType = viewName === "suggestions" || viewName === "detail"

  return (
    <Box forwardCSS={css._viewTitleContainerCSS}>
      <Title {...titleProps}>{viewTitle}</Title>
      {showsOfferType &&
        <OfferTypeIndicator
          forwardCSS={css._viewHeaderOfferTypeCSS}
          offerType={offerType}
        />
      }
      {viewSubtitle && 
        <p css={css._viewSubTitleCSS}>{viewSubtitle}</p>
      }
    </Box>
  );
};

export default ViewInfo;
