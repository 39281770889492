import React from "react";

import * as lib from "../lib";

import ViewContainer from "../ViewContainer";
import LoadingBar from "../generic/LoadingBar/LoadingBar";
import * as css from "./Evalutate.css"
import { BetaBadge, Box } from "../generic";

const EvaluateInProgress = ({ submissionData }: lib.TViewContextProps) => {
  const focusRef = lib.useFocusRef<HTMLHeadingElement>({
    documentCookie: document.cookie,
    dependencies: [], // Run once
    shouldFocus: true,
  });

  return (
    <ViewContainer
      viewName="evaluate:in-progress"
      htmlTitle={`Abstract analysis in progress for ${submissionData.title}`}
      topTitle="Improve your abstract for"
      submissionData={submissionData}
    >
      <Box>
        <BetaBadge />
        <h1 
          css={css._evaluateTitle}
          data-testid="evaluate-title" ref={focusRef} tabIndex={-1}>
          <strong>We&apos;re analysing your abstract</strong>
        </h1>
        <p>This should take less than one minute, please don&apos;t reload the page.</p>
        <LoadingBar />
      </Box>
    </ViewContainer>
  );
};

export default EvaluateInProgress;
