import React, { useState } from "react"

import * as lib from "../lib"

import ViewContainer from "../ViewContainer"
import { TAbstractData } from "../lib/types/AbstractData.types"
import { BetaBadge, Box, Button, ExternalLink, HorizonalRule, UtilBar } from "../generic"
import AbstractInfo from "./AbstractInfo"
import * as css from "./Evalutate.css"
import SubmissionInstructions from "../StatusView/SubmissionInstructions"
import BackToTransferStatusLink from "./BackToTransferStatusLink"
import LoginToJournalLink from "../StatusView/LoginToJournalLink"

const EvaluateCompleted = ({
  abstractData,
  submissionData
}: lib.TViewContextProps & {
  abstractData: TAbstractData
}) => {
  const { transfer } = submissionData;

  const targetJournalTitle = transfer?.targetJournal ? transfer.targetJournal.title : "the journal"
  
  const focusRef = lib.useFocusRef<HTMLHeadingElement>({
    documentCookie: document.cookie,
    dependencies: [], // Run once
    shouldFocus: true
  })

  const [copyStatus, setCopyStatus] = useState<"succeeded" | "failed" | "uncopied">("uncopied");

  const getCopyFeedback = () => {
    if (copyStatus === "uncopied") {
      return null;
    }
    if (copyStatus === "succeeded") {
      return <p>Text copied to clipboard</p>
    }
    if (copyStatus === "failed") {
      return <p>Text could not be copied to clipboard. Please try again.</p>
    }
  }

  const copyFeedback = getCopyFeedback()

  const handleCopySuggestedAbstract = () => {
    lib.trackFormButtonClick("copyAmended")

    navigator.clipboard.writeText(abstractData.editedAbstract)
      .then(() => {
        setCopyStatus("succeeded");
        setTimeout(() => {
          setCopyStatus("uncopied");
        }, 3000);
      })
      .catch(() => {
        setCopyStatus("failed");
        setTimeout(() => {
          setCopyStatus("uncopied");
        }, 3000);
      });
  };

  const getScoreCategory = (score: number) => {
    if (score >= 0 && score < 0.60) {
      return {
        category: 'low',
        message: 'Strong chance of rejection due to language.'
      };
    } else if (score >= 0.60 && score <= 0.80) {
      return {
        category: 'middle',
        message: 'Moderate chance of rejection due to language.'
      };
    } else if (score > 0.80) {
      return {
        category: 'high',
        message: 'Very minor chance of rejection due to language.'
      };
    }
  }

  const scoreCategory = getScoreCategory(abstractData.score)

  return (
    <ViewContainer
      viewName="evaluate:completed"
      htmlTitle={`Abstract analysis complete for ${submissionData.title}`}
      topTitle="Improve your abstract for"
      submissionData={submissionData}
    >
      <Box>
        <BetaBadge />
        <div css={css._evaluateSummaryCSS}>
          <div className="main-column">
            <h1
              css={css._evaluateTitle}
              data-testid="evaluate-title"
              ref={focusRef}
              tabIndex={-1}
            >
              <strong>Analysis complete!</strong>
            </h1>
            <p>We&apos;ve analysed your abstract and suggested some changes to increase your chances of publication. You can copy your amended abstract and use it to replace the existing one when you edit your submission to {targetJournalTitle}.</p>
          </div>
          <section className={`side-column evaluate-score-${scoreCategory?.category}`}>
            <h2><strong>Language score: {Math.round(abstractData.score * 100)}%</strong></h2>
            <p>{scoreCategory?.message}</p>
          </section>
        </div>
      </Box>
      <HorizonalRule spacing="none" />
      <Box>
        <h2 id="current-abstract"><strong>Abstract before analysis</strong></h2>
        <AbstractInfo abstract={abstractData.originalAbstract} describedBy="current-abstract" />
      </Box>
      <Box>
        <h2 id="new-abstract"><strong>Abstract after analysis</strong></h2>
        <AbstractInfo abstract={abstractData.editedAbstract} describedBy="new-abstract" />
      </Box>
      <Box 
        color="info"
        forwardCSS={css._copyContainerCSS}
        variant="background"
      >
        <div className="main-column">
          <h2><strong>Submit using your amended abstract instead?</strong></h2>
          <p>
            Copy your amended abstract to use it in your new submission to {targetJournalTitle}. Alternatively login and complete your submission using your existing abstract.
          </p>
          <p>
            Note: you will need to add a statement to your paper declaring that generative AI has been used to improve readability and language.
            {" "}
            <ExternalLink 
              analyticsRegion="ah-evaluate"
              analyticsName="ah-goto-about-gen-ai-declaration"
              href="https://www.elsevier.com/about/policies-and-standards/publishing-ethics#4-duties-of-authors"
              testId="about-gen-ai-declaration-link"
            >
              Read more about the declaration <span css={lib._srOnlyCSS}>regarding generative AI</span>
            </ExternalLink>
          </p>
        </div>
        <UtilBar className="side-column">
          <Button 
            icon={{name: "duplicate-document", position: "after"}}
            testId="copy-amended"
            styleType="secondary"
            onClick={handleCopySuggestedAbstract}
          >
            Copy amended abstract
          </Button>
          <div aria-live="polite">
            {copyFeedback}
          </div>
        </UtilBar>
      </Box>
      {transfer?.targetJournal &&
        <Box>
          <UtilBar>
            <LoginToJournalLink 
              analyticsRegion="ah-evaluate"
              targetJournal={transfer.targetJournal}
              variant="button"        
            />
            <BackToTransferStatusLink />
          </UtilBar>
          <SubmissionInstructions 
            analyticsRegion="ah-evaluate"
            targetJournal={transfer.targetJournal}
          />
        </Box>
      }
    </ViewContainer>
  )
}

export default EvaluateCompleted
