import React from "react"

import { Button } from "../generic"

export const TransferButton = ({
  onClick,
  testId
}: {
  onClick: () => void
  testId: string
}) => (
  <Button
    icon={{ name: "navigate-right", position: "after" }}
    onClick={onClick}
    testId={testId}
  >
    Transfer to this journal
  </Button>
)
