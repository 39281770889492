import { css } from "@emotion/react";

import { TButtonLinkStyleType } from "./BL.types";

/* All buttons */

const baseCSS = css`
  cursor: pointer;
  display: inline-block;
  vertical-align: baseline;
  line-height: var(--line-height);
  padding: 0;
  border: 0;
  font-size: inherit;
  line-height: 0;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  color: var(--color-interaction);
  transition: all 0.2s;
  &:has(.button-link-icon-before),
  &:has(.button-link-icon-after) {
    .button-link-inside {
      display: grid;
      justify-content: center;
      align-items: center;
      gap: 0.75em;
    }
  }
  &:has(.button-link-icon-before) {
    .button-link-inside {
      grid-template-columns: min-content max-content;
    }
  }
  &:has(.button-link-icon-after) {
    .button-link-inside {
      grid-template-columns: max-content min-content;
    }
  }
  .button-link-content {
    color: inherit;
    line-height: var(--line-height);
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-on-interaction), 0 0 0 4px var(--color-interaction);
  }
  &:focus:hover {
    box-shadow: none;
  }
`

const withBorderCSS = css`
  padding: calc(0.5em - 2px) calc(1.25em - 4px);
  border: 2px solid;
`;

const primaryCSS = css`
  ${withBorderCSS}
  border-color: var(--color-interaction);
  background-color: var(--color-interaction);
  color: var(--color-on-interaction);
  &:hover {
    border-color: var(--color-interaction-dim);
    background-color: var(--color-interaction-dim);
    color: var(--color-on-interaction);
  }
  &[disabled] {
    cursor: initial;
    border-color: var(--color-disabled);
    background-color: var(--color-disabled);
    color: var(--color-on-disabled);
  }
`

const secondaryCSS = css`
  ${withBorderCSS}
  border-color: var(--color-interaction);
  background-color: var(--color-on-interaction);
  color: var(--color-interaction);
  &:hover {
    border-color: var(--color-interaction-dim);
    background-color: var(--color-on-interaction);
    color: var(--color-interaction-dim);
  }
  &[disabled] {
    cursor: initial;
    border-color: var(--color-disabled);
    background-color: var(--color-on-disabled);
    color: var(--color-on-disabled);
  }
`

/* Buttons without borders */

const withoutBorderCSS = css`
  white-space: normal;
  color: var(--color-interaction);
`

const textCSS = css`
  ${withoutBorderCSS}
  .button-link-content {
    text-decoration: underline;
    text-decoration-color: transparent;   
  }
  &:hover .button-link-content {
    text-decoration-color: var(--color-interaction);
  }
  &:focus .button-link-content {
    text-decoration-color: transparent;    
  }
  &:active .button-link-content {
    text-decoration-color: var(--color-interaction);
  }
  &[disabled] .button-link-content {
    cursor: initial;
    text-decoration-color: transparent;
    color: var(--color-disabled);
  }
`

const textUnderlinedCSS = css`
  ${withoutBorderCSS}
  .button-link-content {
    text-decoration: underline;
    text-decoration-color: var(--color-interaction);   
  }
  &:hover .button-link-content, 
  &:focus .button-link-content {
    text-decoration-color: transparent;    
  }
  &:active .button-link-content {
    text-decoration-color: var(--color-interaction);
  }
  &[disabled] .button-link-content {
    cursor: initial;
    text-decoration-color: transparent;
    color: var(--color-disabled);
  }
`

const navCSS = css`
  --color-interaction: var(--color-on-surface);
  ${textCSS}
`

export const buttonLinkCSS = ({
  styleType,
}: {
  styleType: TButtonLinkStyleType;
}) => {
  switch (styleType) {
    case "primary":
      return [baseCSS, primaryCSS];
    case "secondary":
      return [baseCSS, secondaryCSS];
    case "text":
      return [baseCSS, textCSS];
    case "text-underlined":
      return [baseCSS, textUnderlinedCSS];
    case "nav":
      return [baseCSS, navCSS];
  }
}

export const buttonLinkIconCSS = css`
  max-width: calc(var(--line-height) - 4px);
  max-height: calc(var(--line-height) - 4px);
`
