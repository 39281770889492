import { css } from "@emotion/react";

// For Pendo button and cookie settings buttons in footer
export const _externalFooterButtonCSS = css`
  display: inline-block !important;
  vertical-align: baseline !important;
  position: static !important;
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: var(--color-interaction) !important;
  cursor: pointer !important;
  font-size: inherit !important;
  line-height: inherit !important;
  padding: 0 !important;
  text-decoration: none !important;
  transition: all 0.2s !important;
  &:hover {
    text-decoration: underline !important;
  }
  &:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 2px var(--color-on-interaction), 0 0 0 4px var(--color-interaction) !important;
    text-decoration: none !important;
  }
  &:focus:hover {
    text-decoration: none !important;
  }
`

export const _externalFooterButtonInsideCSS = css`
  color: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  position: inherit !important;
  text-align: left !important;
`