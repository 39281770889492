import { useQuery } from "@tanstack/react-query"

import { apiClient } from ".."

export const useSubmissionDataFromUuid = (submissionUuid: string) => {
  return useQuery({ 
    queryKey: ["submissionData", submissionUuid], 
    queryFn: async () => {
      const response = await apiClient({
        url: `/submissions/${submissionUuid}`
      });
  
      return response;
    },
    enabled: !!submissionUuid, // Don't try to query the api unless a valid submission uuid is available
  })
}
