import { css } from "@emotion/react";
import { _minMediaCSS } from "../lib";

export const _journalCardGridCSS = css`
  display: grid;
  grid-column-gap: var(--column-gap);
  grid-row-gap: var(--row-gap);
  
  grid-template-columns: 1fr; 
  ${_minMediaCSS("xs")} {
    grid-template-columns: max-content repeat(2, 1fr); 
  }
  ${_minMediaCSS("s")} {
    grid-template-columns: max-content repeat(3, 1fr);
  }
  ${_minMediaCSS("m")} {
    grid-template-columns: max-content repeat(5, 1fr);
  }
  ${_minMediaCSS("l")} {
    grid-template-columns: max-content repeat(7, 1fr); 
  }
  ${_minMediaCSS("xl")} {
    grid-template-columns: repeat(10, 1fr); 
  }
`

export const _journalCardCoverCSS = css`
  ${_minMediaCSS("xs")} {
    grid-column: 1 / 1;
    grid-row: 1 / span 2;
  }
  ${_minMediaCSS("s")} {
    grid-column: 1 / 1;
    grid-row: 1 / span 4;
  }

`

export const _journalCardContentCSS = ({
  size,
  hasSubGrid,
}: {
  size: "s" | "m" | "l"
  hasSubGrid?: boolean
}) => {
  return css`
    ${hasSubGrid ? css`    
      display: grid;
      grid-template-columns: subgrid;
    ` : ""}

    ${_minMediaCSS("xs")} {
      grid-column: ${size !== "s" ? "1" : "2"} / span 3; 
    }
    ${_minMediaCSS("s")} {
      grid-column: 2 / span 3;
    }
    ${_minMediaCSS("m")} {
      grid-column: 2 / ${size !== "s" ? "span 5" : "span 3"};
    }
    ${_minMediaCSS("l")} {
      grid-column: 2 / ${size === "l" ? "span 7" : "span 5"};
      grid-column-gap: calc(var(--column-gap) * 3);
    }
    ${_minMediaCSS("xl")} {
      grid-column: 2 / ${size === "l" ? "span 9" : "span 6"};
      grid-column-gap: calc(var(--column-gap) * 3);
    }
`}

export const _journalCardCTACSS = ({
  size
}: {
  size: "s" | "m" | "l"
}) => css`
  --margin: 0;
  font-size: var(--font-size-s);
  ${_minMediaCSS("xs")} {
    grid-column: ${size == "s" ? "2" : "1"} / span 2;
  }
  ${_minMediaCSS("s")} {
    grid-column: 2 / span 2;
  }
  ${_minMediaCSS("m")} {
    grid-column: ${size == "s" ? "5" : "2"} / span 2;
    ${size == "s" ? "text-align: right;" : ""};
  }
  ${_minMediaCSS("l")} {
    grid-column: 7 / span 2;
    text-align: right;
  }
  ${_minMediaCSS("xl")} {
    grid-column: 8 / span 3;
  }
`

export const _journalCardMainTitleCSS = css`
  margin: 0;
  font-size: var(--font-size-xl);
`

export const _journalCardGPR = css`
  flex-wrap: nowrap;
  font-size: var(--font-size-s);
`
