import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AppErrorType, trackInitialLoad } from "./lib";
import ErrorBoundary from "./ErrorBoundary";
import ErrorView from "./ErrorView";
import StartView from "./StartView";
import ValidateAcceptLink from "./ValidateAcceptLink";
import StatusViewRouter from "./StatusView/StatusViewRouter";
import SuggestionsViewRouter from "./SuggestionsView/SuggestionsViewRouter";
import DetailViewRouter from "./DetailView";
import EvaluateViewRouter from "./EvaluateView/EvaluateViewRouter";

const queryClient = new QueryClient({
  // ✅ Keep react-query from refetching too often
  defaultOptions: {
    queries: {
      refetchOnMount: false, // Prevent react-query from doing a hard reload when react-router changes routes
      refetchOnReconnect: false, // Overkill
      refetchOnWindowFocus: false, // Prevent react-query from fetching too soon
      staleTime: (1000 * 60 * 60 * 3) + (1000 * 60 * 10), // 3 hours and 10 minutes, same as ingest timeout + buffer
    }
  }
});

const App = () => {
  useEffect(() => trackInitialLoad(), [])

  const { pathname } = new URL(window.location.href);

  return (
    <ErrorBoundary
      fallback={
        <ErrorView errorType={AppErrorType.JAVASCRIPT_ERROR} />
      }
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/transfers/*">
              <Route index={true} element={<ValidateAcceptLink />} />
              <Route path=":submissionUuid" element={<StartView />}>
                <Route index={true} element={<SuggestionsViewRouter />} />
                <Route path="status" element={<StatusViewRouter />} />
                <Route path="evaluate" element={<EvaluateViewRouter />} />
                <Route path=":issn" element={<DetailViewRouter />} />
                <Route path="*" element={
                  <ErrorView 
                    errorType={AppErrorType.INVALID_URL}
                  />
                } />
              </Route>
            </Route>
            <Route 
              path="/resubmissions/*"
              element={
                <Navigate 
                  to={pathname.replace("/resubmissions", "/transfers")} 
                  replace={true}
                />
              }
            />
            <Route path="*" element={
              <ErrorView 
                errorType={AppErrorType.INVALID_URL}
              />
            } />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
