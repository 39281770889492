import { useQuery } from "@tanstack/react-query"

import { apiClient } from ".."

export const useEvaluateManuscript = ({
  submissionUuid,
  hasConsent
}: {
  submissionUuid: string,
  hasConsent?: boolean
}) => {
  return useQuery({ 
    queryKey: ["abstractData", submissionUuid], 
    queryFn: async () => {
      const response = await apiClient({
        url: `/submissions/${submissionUuid}/language-editing`
      });
  
      return response;
    },
    enabled: !!submissionUuid && !!hasConsent, // Don't try to query the api unless a valid submission uuid is available
  })
}
