import React from "react";

import { 
  _pendoFloatingFeedbackButtonCSS,
  _pendoFloatingFeedbackContainerCSS
} from "./PendoContainers.css";

export const PendoFloatingFeedback = ({aaPageName}: {aaPageName: string}) => {
  return (
    <div 
      css={[
        _pendoFloatingFeedbackButtonCSS,
        _pendoFloatingFeedbackContainerCSS
      ]}
      data-pendo-page={`pendo-${aaPageName}-page`}
      data-pendo-id="floating-feedback"
      data-testid="pendo-floating-feedback"
    >
      &nbsp;
    </div>
  )
}
