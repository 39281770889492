import { ReactNode } from "react"
import { 
  TAppError,
  TSubmissionData
} from "../lib"

export type TViewName = 
  "suggestions" | 
  "detail" | 
  "status" | 
  // Hard coding for the experiment
  "evaluate:consent" | 
  "evaluate:in-progress" | 
  "evaluate:completed" | 
  "error"

interface TDataViewBase {
  children: React.ReactNode | React.ReactNode[] 
  htmlTitle: string
  topTitle: string
  topSubtitle?: string
  submissionData: TSubmissionData
}

export interface TSuggestionsView extends TDataViewBase {
  viewName: "suggestions"
}

export interface TDetailView extends TDataViewBase {
  viewName: "detail"
  navigation: ReactNode
  suggestedJournal: {
    issn: string,
    title: string
  },
}

export interface TStatusView extends TDataViewBase {
  viewName: "status"
}

export interface TEvaluateConsentView extends TDataViewBase {
  viewName: "evaluate:consent"
}

export interface TEvaluateInProgressView extends TDataViewBase {
  viewName: "evaluate:in-progress"
}

export interface TEvaluateCompletedView extends TDataViewBase {
  viewName: "evaluate:completed"
}

export type TDataView = TSuggestionsView | TDetailView | TStatusView | TEvaluateConsentView | TEvaluateInProgressView | TEvaluateCompletedView

export interface TErrorView extends TAppError {
  viewName: "error"
  children: React.ReactNode | React.ReactNode[]
  htmlTitle: string
  submissionData?: TSubmissionData
}

export function isSuggestionsView(
  t: TDataView | TErrorView
): t is TSuggestionsView {
  return (
    t.viewName === "suggestions"
  )
}

export function isDetailView(
  t: TDataView | TErrorView
): t is TDetailView {
  return (
    t.viewName === "detail" &&
    "navigation" in t &&
    "suggestedJournal" in t
  )
}

export function isStatusView(
  t: TDataView | TErrorView
): t is TStatusView {
  return (
    t.viewName === "status"
  )
}

export function isEvaluateView(
  t: TDataView | TErrorView
): t is TEvaluateConsentView | TEvaluateInProgressView | TEvaluateCompletedView {
  return (
    t.viewName.startsWith("evaluate:")
  )
}

export function isDataView(
  t: TDataView | TErrorView
): t is TDataView {
  return (
    isSuggestionsView(t) ||
    isDetailView(t) ||
    isStatusView(t) ||
    isEvaluateView(t)
  )
}

export function isErrorView(
  t: TDataView | TErrorView
): t is TErrorView {
  return (
    "errorType" in t &&
    t.viewName === "error"
  )
}
