import React, { Fragment } from "react"

import { reactKey, TSuggestedJournalData } from "../lib"
import { Box, InternalLink } from "../generic"
import JournalCard from "../JournalCard"
import { _goaDescCSS, _goaTitleCSS } from "./SuggestionsView.css"

const SuggestionsList = ({
  suggestedJournals,
}: {
  suggestedJournals: TSuggestedJournalData[]
}) => {
  return (suggestedJournals.map(journal => {
    const cardId = journal.isGoldenOpenAccess ? "promoted" : journal.ranking

    const cta = (descriptionId?: string) => (
      <InternalLink 
        analyticsRegion="ah-journal-suggestions"
        analyticsName={`ah-journal-view-more-${cardId}`}
        aria-describedby={descriptionId}
        icon={{name: "navigate-right", position: "after"}}
        href={journal.issn}
        styleType="primary"
        testId={`journal-view-more-link-${cardId}`}
      >
        View details and transfer
      </InternalLink>
    )

    return (
      <Fragment key={reactKey({journal})}>
        {journal.isGoldenOpenAccess &&
          <Box testId={`journal-goa-${cardId}`}>
            <h2 css={_goaTitleCSS}>You might also be interested in this journal</h2>
            <p css={_goaDescCSS}>This multidisciplinary journal accepts manuscripts in a broad range of disciplines.</p>
          </Box>
        }
        <JournalCard 
          analyticsRegion="ah-journal-suggestions"
          cta={cta}
          journal={journal}
          level={journal.isGoldenOpenAccess ? 3 : 2}
          shouldDescribe={true}
        />
      </Fragment>
    )
  }))
}

export default SuggestionsList
