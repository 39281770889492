import React from "react";
import { Navigate } from "react-router-dom";

import * as lib from "../lib";
import { LoadingIndicator } from "../generic";
import ErrorView from "../ErrorView";

const ValidateAcceptLink = () => {
  const { queryParams } = lib.useUrl()

  if (!queryParams) {
    return (
      <ErrorView
        errorType={lib.AppErrorType.INVALID_URL}
      />
    )
  }

  const { isLoading, data, error } = lib.useSubmissionDataFromQueryParams(queryParams)
  
  // It is *not safe* to assume that submission data exists if there is no error. Someday we may figure out why...
  if (isLoading) {
    return <LoadingIndicator />
  } else if (data) {
    const {submissionUuid} = data.data;
    return <Navigate to={`/transfers/${submissionUuid}`} replace={true} />
  } else {
    const { errorType, errorDetails } = lib.handleApiError(error);
    return (
      <ErrorView
        errorType={errorType}
        errorDetails={errorDetails}
      />
    )
  }
};

export default ValidateAcceptLink;
