import {
  AppErrorType,
  TOfferType,
  TAppError,
} from ".."

// Convert to different format for compatibility with Adobe
export const aaManuscriptId = ({
  documentId,
  emAcronym,
  revision = 0
}: {
  emAcronym: string
  documentId: number
  revision?: number
}) => `${emAcronym}-${documentId}.${revision}`

// From https://elsevier.atlassian.net/wiki/spaces/EOS/pages/54451250039/Error+Messages
export const aaErrorCodes = (submissionError: TAppError) => {
  const {
    errorType,
    errorDetails
  } = submissionError

  return {
    [AppErrorType.API_ERROR]: `se-ah_${errorDetails || "unknown"}`,
    [AppErrorType.JAVASCRIPT_ERROR]: "ae-ah_ui_error",
    [AppErrorType.NETWORK_UNAVAILABLE]: "se-ah_api_unavailable",
    [AppErrorType.NO_SUGGESTIONS]: "se-ah_no_suggestions",
    [AppErrorType.SUBMISSION_NOT_FOUND]: "se-ah_submission_not_found",
    [AppErrorType.INVALID_URL]: "se-ah_invalid_url",
  }[errorType]
}

// Convert to different format for compatibility with Adobe
export const aaOfferType = (offerType: TOfferType = "Unknown") => {
  return {
    "RejectWithAISuggestions": "reject-with-machine-suggestions",
    "RejectWithEditorIndependentSuggestions": "reject-with-editor-independent-suggestions",
    "TransferByScientificManagingEditor": "transfer-by-scientific-managing-editor",
    "TransferWithEditorSuggestions": "transfer-with-editor-suggestions",
    "Unknown": "offer-type-unknown",
  }[offerType]
}

export function aaTruncate(string?: string) {
  if (!string) {
    return undefined
  } else if (string.length < 100) {
    return string
  } else {
    const substring = string.slice(0, 100)
    return `${substring.slice(0, substring.lastIndexOf(" "))}...`
  }
}

/*
 * Adobe Analytics requires events only get sent once the document has been
 * rendered. This function wraps a function passed in and either calls it
 * immediately if the readyState is complete or sticks it in a state change
 * listener.
 */
export const readyStateDeferrer = ({
  sendAnalytics,
}: {
  sendAnalytics: () => void
}) => {
  if (document.readyState === "complete") {
    sendAnalytics()
  } else {
    document.onreadystatechange = function () {
      if (document.readyState === "complete") {
        sendAnalytics()
      }
    }
  }
}
