import React from "react"

import * as lib from "../lib"

import ViewContainer from "../ViewContainer"
import { Box, BetaBadge, Button, UtilBar } from "../generic"
import AbstractInfo from "./AbstractInfo"
import * as css from "./Evalutate.css"
import BackToTransferStatusLink from "./BackToTransferStatusLink"

const EvaluateConsent = ({
  submissionData,
  setEvaluateStatus
}: lib.TViewContextProps & {
  setEvaluateStatus: React.Dispatch<React.SetStateAction<"AWAITING_CONSENT" | "IN_PROGRESS" | "COMPLETED">>
}) => {
  const focusRef = lib.useFocusRef<HTMLHeadingElement>({
    documentCookie: document.cookie,
    dependencies: [], // Run once
    shouldFocus: true
  })

  const { transfer } = submissionData

  const targetJournalTitle = transfer?.targetJournal ? transfer.targetJournal.title : "the journal"

  return (
    <ViewContainer
      viewName="evaluate:consent"
      htmlTitle={`Improve your abstract for ${submissionData.title}`}
      topTitle="Improve your abstract for"
      submissionData={submissionData}
    >
      <Box>
        <BetaBadge />
        <h1
          css={css._evaluateTitle}
          data-testid="evaluate-title"
          ref={focusRef}
          tabIndex={-1}
        >
          <strong>
            Improve your abstract quickly and confidentially for free
          </strong>
        </h1>
        <p>
          Using generative AI, in less than one minute, we can evaluate your abstract, provide a language quality score and improve your likelihood of acceptance &mdash; all confidentially.
        </p>
        <p>
          You can then replace your existing abstract when you complete your submission to {targetJournalTitle}, if you wish.
        </p>
      </Box>
      <Box>
        <h2 id="current-abstract"><strong>Your current abstract</strong></h2>
        <AbstractInfo abstract={submissionData.abstract} describedBy="current-abstract" />
      </Box>
      <Box>
        <UtilBar>
          <Button
            testId="confirm-analyse-button"
            onClick={() => {
              lib.trackFormButtonClick("confirmAnalyseLanguage")
              setEvaluateStatus("IN_PROGRESS")
            }}
          >
            Analyse my abstract
          </Button>
          <BackToTransferStatusLink />
        </UtilBar>
      </Box>
    </ViewContainer>
  )
}

export default EvaluateConsent
