import React from "react";

import DOMPurify from "dompurify";

import { _journalScopeCSS } from "./JournalScope.css";

const JournalScope = ({ scope }: { scope: string }) => {
  const sanitizedScope = DOMPurify.sanitize(scope);
  return (
    <div
      css={_journalScopeCSS}
      dangerouslySetInnerHTML={{ __html: sanitizedScope }}
    />
  );
};

export default JournalScope;
