import React from "react"
import { Navigate, useOutletContext } from "react-router-dom"

import { 
  AppErrorType,
  isOfferStatusActive,
  TViewContextProps,
  useSuggestedJournal,
  useUrl
} from "../lib"

import DetailView from "./DetailView"
import ErrorView from "../ErrorView"
import DetailNavigation from "../DetailNavigation"
import ViewContainer from "../ViewContainer"

const DetailViewRouter = () => {
  const {
    dispatchOfferStatusUpdate,
    submissionData
  } = useOutletContext<TViewContextProps>()

  const {
    status,
    submissionUuid,
    suggestedJournals
  } = submissionData

  if(!isOfferStatusActive(status)) {
    return <Navigate to={`/transfers/${submissionUuid}/status`} />
  }

  const { issn: suggestedJournalIssn } = useUrl()
  
  const suggestedJournal = useSuggestedJournal({
    issn: suggestedJournalIssn,
    suggestedJournals
  })

  if (!suggestedJournal) {
    return (
      <ErrorView
        errorType={AppErrorType.INVALID_URL}
        submissionData={submissionData}
      />
    )
  }

  const thisJournalIndex = suggestedJournals.findIndex(
    (journal) => journal.issn === suggestedJournal.issn
  )

  const previousJournal = suggestedJournals[thisJournalIndex - 1]
  const nextJournal = suggestedJournals[thisJournalIndex + 1]

  return (
    <ViewContainer
      viewName="detail"
      htmlTitle={`${suggestedJournal.title} suggested for ${submissionData.title}`}
      topTitle="Transfer suggestion for"
      submissionData={submissionData}
      suggestedJournal={{
        title: suggestedJournal.title,
        issn: suggestedJournal.issn
      }}
      navigation={
        <DetailNavigation 
          submissionUuid={submissionUuid}
          thisJournalIndex={thisJournalIndex}
          journalsLength={suggestedJournals.length}
          previousJournalISSN={previousJournal ? previousJournal.issn : undefined}
          nextJournalISSN={nextJournal ? nextJournal.issn : undefined}      
        />
      }
    >
      <DetailView
        dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
        offerType={submissionData.offerType}
        suggestedJournal={suggestedJournal}
      />
    </ViewContainer>
  )
}

export default DetailViewRouter
