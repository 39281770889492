import { css } from "@emotion/react";

import { _maxMediaCSS, _minMediaCSS } from "../lib/cssUtils/mediaQuery.css";
import { _utilBarContainerCSS } from "../generic/UtilBar/UtilBar.css";

export const _bannerContainerCSS = css`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: var(--spacing-s) var(--page-gap);
  border-bottom: 1px solid var(--color-border);
`

export const _skipToContentCSS = css`
  position: absolute;
  top: 1.15em;
  left: 0;
  transform: translate(-100%, 0);
  &:focus {
    transform: translate(0, 0);
  }
`

export const _logoTitleContainerCSS = css`
  flex: 1;
  flex-wrap: nowrap;
`

export const _logoContainerCSS = css`
  height: 2em;
  ${_minMediaCSS("s")} {
    height: 3em;
  }
`

export const _logoCSS = css`
  aspect-ratio: 73/80;
  display: block;
  width: auto;
  max-height: 100%;
`;

export const _appTitleCSS = css`
  font-family: ElsevierDisplayLight, var(--es-font-sans-serif);
  line-height: 1.25em;
  color: var(--color-nav-link);
  font-size: var(--font-size-l);
  ${_maxMediaCSS("s")} {
    font-size: var(--font-size-m);
  }
`

export const _desktopMenuCSS = css`
  ${_utilBarContainerCSS}
  padding: 0;
  list-style: none;
  ${_maxMediaCSS("m")} {
    display: none;
  }
`

export const _mobileMenuCSS = css`
  ${_minMediaCSS("m")} {
    display: none;
  }
`

export const _mobileMenuLinksCSS = css`
  padding: 0;
  list-style: none;
`
