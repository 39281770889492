/*
  The evils of enums in Typescript: https://dev.to/ivanzm123/dont-use-enums-in-typescript-they-are-very-dangerous-57bh
  Best solution: https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
*/

export const AppErrorType = {
  SUBMISSION_NOT_FOUND: "SUBMISSION_NOT_FOUND",
  NO_SUGGESTIONS: "NO_SUGGESTIONS",
  API_ERROR: "API_ERROR",
  JAVASCRIPT_ERROR: "JAVASCRIPT_ERROR",
  NETWORK_UNAVAILABLE: "NETWORK_UNAVAILABLE",
  INVALID_URL: "INVALID_URL"
} as const
export type TAppErrorType = typeof AppErrorType[keyof typeof AppErrorType]

// From https://elsevier.atlassian.net/wiki/spaces/EOS/pages/54451250039/Error+Messages
export const AppErrorCode = {
  [AppErrorType.SUBMISSION_NOT_FOUND]: 1000,
  [AppErrorType.NO_SUGGESTIONS]: 1001,
  [AppErrorType.API_ERROR]: 1002,
  [AppErrorType.JAVASCRIPT_ERROR]: 1003,
  [AppErrorType.NETWORK_UNAVAILABLE]: 1004,
  [AppErrorType.INVALID_URL]: 1005
} as const
export type TAppErrorCode = typeof AppErrorCode[keyof typeof AppErrorCode]

export type TErrorApiError = typeof AppErrorType.API_ERROR
export type TErrorInvalidUrl = typeof AppErrorType.INVALID_URL
export type TErrorJavascriptError = typeof AppErrorType.JAVASCRIPT_ERROR
export type TErrorNetworkUnavailable = typeof AppErrorType.NETWORK_UNAVAILABLE
export type TErrorNoSuggestions = typeof AppErrorType.NO_SUGGESTIONS
export type TErrorSubmissionNotFound = typeof AppErrorType.SUBMISSION_NOT_FOUND

export type TAppError = {
  errorType: TAppErrorType
  errorDetails?: string | number | Record<string, unknown>
}

export function isApiError(
  t: string
): t is TErrorApiError {
  return t === AppErrorType.API_ERROR
}

export function isJavascriptError(
  t: string
): t is TErrorJavascriptError {
  return t === AppErrorType.JAVASCRIPT_ERROR
}

export function isInvalidUrlError(
  t: string
): t is TErrorJavascriptError {
  return t === AppErrorType.INVALID_URL
}

export function isNetworkUnavailableError(
  t: string
): t is TErrorNetworkUnavailable {
  return t === AppErrorType.NETWORK_UNAVAILABLE
}

export function isNoSuggestionsError(
  t: string
): t is TErrorNoSuggestions {
  return t === AppErrorType.NO_SUGGESTIONS
}

export function isSubmissionNotFoundError(
  t: string
): t is TErrorSubmissionNotFound {
  return t === AppErrorType.SUBMISSION_NOT_FOUND
}
