import React from "react"

import {
  Box,
  ExternalLink,
  Icon
} from "../generic"

import * as css from "./LanguageBanner.css"
import { _srOnlyCSS, languageEditingService } from "../lib"

const LanguageBannerWebShop = ({
  position
}: {
  position: "before-main-heading" | "after-main-heading"
}) => {
  return (
    <Box 
      data-target="language-banner"
      element="section"
      forwardCSS={css._languageBannerWebShopBoxCSS}
      color="promo"
      variant="background"
    >
      <div className="language-banner-content">
        {position === "after-main-heading" ?       
          <h2 
            className="language-banner-title"
            data-testid="language-banner-title"
          >
            Boost your publication chances
          </h2> :
          <p 
            className="language-banner-title"
            data-testid="language-banner-title"
          >
            Boost your publication chances
          </p>
        }
        <p>
          Get published faster. Make sure your article is written in correct English before submission. Articles that get language editing are more likely to be published in a peer-reviewed journal.
        </p>
      </div>
      <div className="language-banner-timing">
        <p css={css._languageBannerWebShopTimingCSS}>
          <Icon name="clock" /><span data-target="language-banner-time">1-7 business days</span>
        </p>
      </div>
      <div className="language-banner-link">
        <ExternalLink 
          analyticsRegion="ah-status"
          analyticsName="ah-goto-language-services"
          data-target="language-banner-link"
          href={languageEditingService}
          styleType="primary"
          testId="language-services-link"
        >
          Find out more <span css={_srOnlyCSS}>about language services</span>
        </ExternalLink>
      </div>
    </Box>
  )
}

export default LanguageBannerWebShop
