import { css } from "@emotion/react";
import { _minMediaCSS } from "../lib";

export const _moreTransferOptionsContainerCSS = css`
  display: grid;
  grid-gap: var(--column-gap) var(--column-gap);
  grid-template-columns: 1fr;
  ${_minMediaCSS("s")} {
    grid-template-columns: 1fr 1fr;
  }
`

export const _moreTransferOptionsTopCSS = css`
  flex-wrap: nowrap;
`

export const _moreTransferOptionsHeadingCSS = css`
  flex: 1;
  white-space: nowrap;
  font-size: var(--font-size-xl);
`

export const _moreTransferOptionsContentCSS = css`
  && {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const _moreTransferOptionsButtonCSS = css`
  ${_minMediaCSS("s")} {
    margin-top: auto;
  }
`

export const _moreTransferOptionsPictoCSS = css`
  width: var(--spacing-xxxxxl);
  height: var(--spacing-xxxxxl);
`

export const _moreTransferOptionsBetaCSS = css`
  margin-bottom: 2rem;
  border-radius: 1rem;
  background: rgb(var(--es-color-secondary-very-pale-blue-rgb));
  color: rgb(var(--es-color-tertiary-blue3-rgb));
`
