import React from "react";

import { ButtonLinkInside } from "./BLInside";
import { buttonLinkCSS } from "./BL.css";
import { _srOnlyCSS, appendTrackingIds } from "../../lib";
import { TLink } from "./BL.types";
import { TIcon } from "../Icon";

interface ExternalLinkProps extends Omit<TLink, "icon"> {
  showIcon?: boolean
}

export const ExternalLink = React.forwardRef(({
  analyticsRegion,
  analyticsName,
  children,
  forwardCSS,
  href,
  showIcon = true,
  styleType,
  testId,
  ...rest
}: ExternalLinkProps, ref: React.Ref<HTMLAnchorElement>) => {
  const icon = showIcon ? {name: "up-right", position: "after"} as TIcon : undefined

  return (
    <a
      {...rest}
      css={[
        buttonLinkCSS({styleType: styleType || "text"}),
        forwardCSS
      ]}
      data-aa-region={analyticsRegion}
      data-aa-name={analyticsName}
      data-testid={testId}
      href={appendTrackingIds(href)}
      rel="noopener nofollow noreferrer"
      ref={ref}
      target="_blank"
    >
      <ButtonLinkInside icon={icon}>
        {children} <span css={_srOnlyCSS}>(external site, opens in new window)</span>
      </ButtonLinkInside>
    </a>
  )
})
