"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _docAbstractRole = _interopRequireDefault(require("./dpub/docAbstractRole"));
var _docAcknowledgmentsRole = _interopRequireDefault(require("./dpub/docAcknowledgmentsRole"));
var _docAfterwordRole = _interopRequireDefault(require("./dpub/docAfterwordRole"));
var _docAppendixRole = _interopRequireDefault(require("./dpub/docAppendixRole"));
var _docBacklinkRole = _interopRequireDefault(require("./dpub/docBacklinkRole"));
var _docBiblioentryRole = _interopRequireDefault(require("./dpub/docBiblioentryRole"));
var _docBibliographyRole = _interopRequireDefault(require("./dpub/docBibliographyRole"));
var _docBibliorefRole = _interopRequireDefault(require("./dpub/docBibliorefRole"));
var _docChapterRole = _interopRequireDefault(require("./dpub/docChapterRole"));
var _docColophonRole = _interopRequireDefault(require("./dpub/docColophonRole"));
var _docConclusionRole = _interopRequireDefault(require("./dpub/docConclusionRole"));
var _docCoverRole = _interopRequireDefault(require("./dpub/docCoverRole"));
var _docCreditRole = _interopRequireDefault(require("./dpub/docCreditRole"));
var _docCreditsRole = _interopRequireDefault(require("./dpub/docCreditsRole"));
var _docDedicationRole = _interopRequireDefault(require("./dpub/docDedicationRole"));
var _docEndnoteRole = _interopRequireDefault(require("./dpub/docEndnoteRole"));
var _docEndnotesRole = _interopRequireDefault(require("./dpub/docEndnotesRole"));
var _docEpigraphRole = _interopRequireDefault(require("./dpub/docEpigraphRole"));
var _docEpilogueRole = _interopRequireDefault(require("./dpub/docEpilogueRole"));
var _docErrataRole = _interopRequireDefault(require("./dpub/docErrataRole"));
var _docExampleRole = _interopRequireDefault(require("./dpub/docExampleRole"));
var _docFootnoteRole = _interopRequireDefault(require("./dpub/docFootnoteRole"));
var _docForewordRole = _interopRequireDefault(require("./dpub/docForewordRole"));
var _docGlossaryRole = _interopRequireDefault(require("./dpub/docGlossaryRole"));
var _docGlossrefRole = _interopRequireDefault(require("./dpub/docGlossrefRole"));
var _docIndexRole = _interopRequireDefault(require("./dpub/docIndexRole"));
var _docIntroductionRole = _interopRequireDefault(require("./dpub/docIntroductionRole"));
var _docNoterefRole = _interopRequireDefault(require("./dpub/docNoterefRole"));
var _docNoticeRole = _interopRequireDefault(require("./dpub/docNoticeRole"));
var _docPagebreakRole = _interopRequireDefault(require("./dpub/docPagebreakRole"));
var _docPagelistRole = _interopRequireDefault(require("./dpub/docPagelistRole"));
var _docPartRole = _interopRequireDefault(require("./dpub/docPartRole"));
var _docPrefaceRole = _interopRequireDefault(require("./dpub/docPrefaceRole"));
var _docPrologueRole = _interopRequireDefault(require("./dpub/docPrologueRole"));
var _docPullquoteRole = _interopRequireDefault(require("./dpub/docPullquoteRole"));
var _docQnaRole = _interopRequireDefault(require("./dpub/docQnaRole"));
var _docSubtitleRole = _interopRequireDefault(require("./dpub/docSubtitleRole"));
var _docTipRole = _interopRequireDefault(require("./dpub/docTipRole"));
var _docTocRole = _interopRequireDefault(require("./dpub/docTocRole"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ariaDpubRoles = [['doc-abstract', _docAbstractRole.default], ['doc-acknowledgments', _docAcknowledgmentsRole.default], ['doc-afterword', _docAfterwordRole.default], ['doc-appendix', _docAppendixRole.default], ['doc-backlink', _docBacklinkRole.default], ['doc-biblioentry', _docBiblioentryRole.default], ['doc-bibliography', _docBibliographyRole.default], ['doc-biblioref', _docBibliorefRole.default], ['doc-chapter', _docChapterRole.default], ['doc-colophon', _docColophonRole.default], ['doc-conclusion', _docConclusionRole.default], ['doc-cover', _docCoverRole.default], ['doc-credit', _docCreditRole.default], ['doc-credits', _docCreditsRole.default], ['doc-dedication', _docDedicationRole.default], ['doc-endnote', _docEndnoteRole.default], ['doc-endnotes', _docEndnotesRole.default], ['doc-epigraph', _docEpigraphRole.default], ['doc-epilogue', _docEpilogueRole.default], ['doc-errata', _docErrataRole.default], ['doc-example', _docExampleRole.default], ['doc-footnote', _docFootnoteRole.default], ['doc-foreword', _docForewordRole.default], ['doc-glossary', _docGlossaryRole.default], ['doc-glossref', _docGlossrefRole.default], ['doc-index', _docIndexRole.default], ['doc-introduction', _docIntroductionRole.default], ['doc-noteref', _docNoterefRole.default], ['doc-notice', _docNoticeRole.default], ['doc-pagebreak', _docPagebreakRole.default], ['doc-pagelist', _docPagelistRole.default], ['doc-part', _docPartRole.default], ['doc-preface', _docPrefaceRole.default], ['doc-prologue', _docPrologueRole.default], ['doc-pullquote', _docPullquoteRole.default], ['doc-qna', _docQnaRole.default], ['doc-subtitle', _docSubtitleRole.default], ['doc-tip', _docTipRole.default], ['doc-toc', _docTocRole.default]];
var _default = ariaDpubRoles;
exports.default = _default;