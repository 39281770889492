import { css } from "@emotion/react";

export const _detailLockupCSS = css`
  .journal-lockup-content {
    --margin: var(--spacing-s);
    font-size: var(--font-size-s);
  }
`

export const _detailSectionCSS = css`
  --margin: var(--spacing-s);
`

export const _detailItemCSS = css`
  color: var(--color-on-surface-dim);  
`

export const _detailSubtitleCSS = css`
  font-weight: bold;
  font-size: var(--font-size-m);
`

export const _detailLinkCSS = css`
  font-size: var(--font-size-s);
`
