import React from "react"
import { Navigate, useOutletContext } from "react-router-dom"

import { AppErrorType, TViewContextProps, hasSuggestions, isOfferStatusActive } from "../lib"

import SuggestionsView from "./SuggestionsView"
import ErrorView from "../ErrorView"

const SuggestionsViewRouter = () => {
  const {
    dispatchOfferStatusUpdate,
    submissionData,
  } = useOutletContext<TViewContextProps>()

  const {
    suggestedJournals,
    status,
    submissionUuid
  } = submissionData

  if(!isOfferStatusActive(status)) {
    return <Navigate to={`/transfers/${submissionUuid}/status`} replace={true} />
  }

  if (!hasSuggestions(suggestedJournals)) {
    return (
      <ErrorView
        errorType={AppErrorType.NO_SUGGESTIONS}
        submissionData={submissionData}
      />
    )
  }

  return (
    <SuggestionsView
      dispatchOfferStatusUpdate={dispatchOfferStatusUpdate}
      submissionData={submissionData}
    />
  )
}

export default SuggestionsViewRouter
