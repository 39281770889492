import React from "react"
import { ExternalLink } from "../generic"
import { _srOnlyCSS, TSuggestedJournalData } from "../lib";

const LoginToJournalLink = ({
  analyticsRegion,
  targetJournal,
  variant
}: {
  analyticsRegion: string
  targetJournal: TSuggestedJournalData
  variant: "button" | "text"
}) => {
  const getEmUrl = ({
    emAcronym
  }: {
    emAcronym: string
  }) => `https://www.editorialmanager.com/${emAcronym.toLowerCase()}/default.aspx`;
  
  const emUrl = getEmUrl({emAcronym: targetJournal.emAcronym})

  const getLinkText = () => {
    if (variant === "button") {
      return (
        <>
          Login to the journal
          <span css={_srOnlyCSS}>{targetJournal.title}</span>
        </>
      )
    } else {
      return `Login to ${targetJournal.title}`
    }
  }

  const linkText = getLinkText()

  return (
    <ExternalLink 
      analyticsName="ah-login-at-journal"
      analyticsRegion={analyticsRegion}
      href={emUrl}
      styleType={variant === "button" ? "primary" : "text"}
      testId="login-at-journal-link"
    >
      {linkText}
    </ExternalLink>
  )
}

export default LoginToJournalLink