import { css } from "@emotion/react";

export const _metricsCASInfoCSS = css`
  grid-column: inherit;
  grid-row: inherit;
`

export const _metricsStatsInfoCSS = css`
  max-width: none;
  margin: 0;
  padding: 0;
  list-style: none;

  grid-row-gap: var(--row-gap);
`

export const _metricsTitleCSS = css`
  display: block;
  white-space: nowrap;
  font-size: var(--font-size-xxs);
`

export const _metricsValueCSS = css`
  display: block;
  font-size: var(--font-size-l);
`;
