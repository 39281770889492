import { DependencyList, useCallback } from "react";

import scrollIntoView from "scroll-into-view-if-needed";

export const useElementRef = <T extends HTMLElement>(
  dependencies: Array<unknown>
) => useCallback((node: T) => {
  return node
}, dependencies);

// https://stackoverflow.com/questions/55838351/how-do-we-know-when-a-react-ref-current-value-has-changed
export const useFocusRef = <T extends HTMLElement>({
  documentCookie,
  dependencies,
  shouldFocus
}: {
  documentCookie: string;
  dependencies: DependencyList;
  shouldFocus: boolean;
}) => useCallback((node: T) => {
  if (node === null) { 
    return;
  } else {
    const canFocus = "focus" in node

    // mayFocus keeps node from steeling focus from cookie banner
    let mayFocus = false;
    // Ignore dev environment because detecting cookies in dev is a nightmare
    if (process.env.NODE_ENV === "development") {
      mayFocus = true;
    } else {
      mayFocus = Boolean(
        documentCookie &&
        documentCookie.includes("OptanonConsent") && 
        documentCookie.includes("OptanonAlertBoxClosed")
      )
    }

    if (canFocus && mayFocus && shouldFocus) {
      setTimeout(() => {
        scrollIntoView(node, {
          scrollMode: "if-needed",
        });
      }, 0);
      setTimeout(() => {
        node.focus();
      }, 200);
    }
  }
}, dependencies);

// Also interesting for the future: https://stackoverflow.com/questions/58886782/how-to-find-focused-react-component-like-document-activeelement