import React from "react";

import { _srOnlySMediaCSS } from "../lib";

import { Box, InternalLink } from "../generic";

import { 
  detailViewBackLinkCSS,
  detailNavigationCSS
} from "./DetailNavigation.css";

const DetailNavigation = ({
  submissionUuid,
  thisJournalIndex,
  journalsLength,
  previousJournalISSN,
  nextJournalISSN
}: {
  submissionUuid: string
  thisJournalIndex: number
  journalsLength: number
  previousJournalISSN?: string
  nextJournalISSN?: string
}) => {
  return (
    <Box>
      <nav
        css={detailNavigationCSS}
        role="navigation"
      >
        <InternalLink 
          analyticsRegion="ah-header"
          analyticsName="ah-to-all-suggestions"
          forwardCSS={detailViewBackLinkCSS}
          href={`/transfers/${submissionUuid}`}
          icon={{name: "navigate-left", position: "before"}}
          styleType="text"
          testId="back-to-suggestions"
        >
          Back<span css={_srOnlySMediaCSS}> to all suggestions</span>
        </InternalLink>
        {previousJournalISSN && (
          <InternalLink 
            analyticsRegion="ah-header"
            analyticsName="ah-previous-journal-suggestion"
            href={`../${previousJournalISSN}`}
            icon={{name: "navigate-left", position: "before"}}
            styleType="text"
            testId="previous-suggestion"
          >
            Previous
          </InternalLink>
        )}
        <span><span css={_srOnlySMediaCSS}>Suggestion </span>{thisJournalIndex + 1} of {journalsLength}</span>
        {nextJournalISSN && (
          <InternalLink 
            analyticsRegion="ah-header"
            analyticsName="ah-next-journal-suggestion"
            href={`../${nextJournalISSN}`}
            icon={{name: "navigate-right", position: "after"}}
            styleType="text"
            testId="next-suggestion"
          >
            Next
          </InternalLink>
        )}
      </nav>
    </Box>
  );
};

export default DetailNavigation;
