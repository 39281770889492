import { TInsightData, TOpenAccessType } from "."
import { TAPCWaiver } from "./APCWaiver.types"
import { TCASInformation } from "./CASInformation.types"

export interface TFeederJournalData {
  emAcronym: string // Not the same as PTS code
  issn: string // "not applicable" for Transfer Plus
  title: string
}

export interface TSuggestedJournalData {
  acceptanceRate?: TInsightData
  acronym: string // PTS code
  apcWaiver?: TAPCWaiver
  casInformation?: TCASInformation
  citeScore?: TInsightData
  editorialBoard: string // Url
  editorInChief: string // Name
  emAcronym: string
  impactFactor?: TInsightData
  isGuaranteedPeerReview?: boolean
  isGoldenOpenAccess?: boolean
  issn: string // "not applicable" for Transfer Plus
  matchingKeywords?: string[]
  openAccessType: TOpenAccessType
  ranking: number
  scope?: string // "(This section is not applicable)" for Transfer Plus. We won't show it so it doesn't matter.
  subjectAreas?: string[]
  title: string
  timeToFirstDecision?: TInsightData
  timeToAcceptance?: TInsightData
}
